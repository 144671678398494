import Image from '@/components/image'
import Link from '@/components/link'
import { P1, P2 } from '@/components/typography'

type Props = {
  title: string
  text: string
  imgSrc: string
  path: string
  toggleNavAndNavItems: () => void
}

export default function NavigationCard({
  title,
  text,
  imgSrc,
  path,
  toggleNavAndNavItems,
}: Props) {
  return (
    <Link href={path} className="navigationCard" onClick={toggleNavAndNavItems}>
      <Image alt="" width="120" height="120" src={imgSrc} />
      <div className="navigationCard__content">
        <P2>{title}</P2>
        <P1>{text}</P1>
      </div>
    </Link>
  )
}
