import { H6, H7 } from '@/components/typography'
import Block from '@/layouts/block'
import Link from '@/components/link'

const Address = ({ className = '' }) => {
  return (
    <div className={`${className}`}>
      <Block className="flex flex-col">
        <H6>Rvdb Amsterdam</H6>
        <H7>Hoogoorddreef 54P</H7>
        <H7>1101 BE, Amsterdam</H7>
      </Block>
      <Block className="flex flex-col">
        <H6>Rvdb Eindhoven</H6>
        <H7>Luchthavenweg 81 | kantoor 247</H7>
        <H7>5657 EA Eindhoven</H7>
      </Block>
      <Block className="flex flex-col">
        <Link href="tel:+310885521200" external>
          <H7 className="addressInfo">+31 (0) 88 552 1200</H7>
        </Link>
        <Link href="mailto:info@rvdb.nl" external>
          <H7 className="addressInfo">info@rvdb.nl</H7>
        </Link>
      </Block>
    </div>
  )
}

export default Address
