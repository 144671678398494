export const ContactLinkedInIcon = ({
  className = '',
  width = '17',
  height = '19',
}) => {
  return (
    <span className={`${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.315441 9.89783C0.315441 7.36989 0.317402 4.84196 0.3125 2.31403C0.3125 1.90021 0.409559 1.53463 0.678187 1.24303C0.940933 0.958933 1.28309 0.911762 1.63211 0.911762C4.24584 0.911762 6.85957 0.911762 9.47428 0.911762C11.4753 0.911762 13.4772 0.91069 15.4782 0.911762C16.1184 0.911762 16.5174 1.19157 16.69 1.77692C16.7459 1.96775 16.7645 2.18002 16.7645 2.38157C16.7684 7.40205 16.7684 12.4215 16.7674 17.442C16.7674 18.172 16.5145 18.6416 16.0057 18.8196C15.8233 18.8839 15.6214 18.9064 15.4282 18.9064C10.8292 18.9107 6.23016 18.9107 1.63113 18.9096C0.761521 18.9096 0.316422 18.425 0.316422 17.4795C0.315441 14.9515 0.316422 12.4236 0.316422 9.89568L0.315441 9.89783ZM6.81251 7.73761V16.3302H9.28605C9.28605 16.2037 9.28605 16.0976 9.28605 15.9915C9.28605 14.5356 9.2586 13.0786 9.29487 11.6239C9.3233 10.4928 9.67134 9.95893 10.6341 9.90211C11.1713 9.86995 11.737 10.1155 11.841 10.8434C11.8949 11.2208 11.9674 11.6003 11.9713 11.9798C11.988 13.3284 11.9792 14.6771 11.9792 16.0258C11.9792 16.1287 11.9792 16.2316 11.9792 16.3334H14.4321V16.0022C14.4321 14.4477 14.4439 12.8932 14.4253 11.3398C14.4194 10.8262 14.3674 10.3063 14.2743 9.80241C14.1694 9.23422 14.0125 8.66173 13.5831 8.27043C12.6802 7.44708 11.6312 7.38704 10.5557 7.6958C9.98605 7.85875 9.53213 8.26507 9.17428 8.81933V7.73868H6.81153L6.81251 7.73761ZM5.29878 16.3399V7.73975H2.8429V16.3388H5.29977L5.29878 16.3399ZM2.65368 5.03815C2.64976 5.90867 3.25858 6.58514 4.04976 6.58728C4.9282 6.5905 5.48016 5.79717 5.47624 5.04565C5.47133 4.16441 4.8478 3.45899 4.05761 3.47079C3.24388 3.48258 2.65858 4.12475 2.65368 5.03815Z"
          fill="#3488A9"
        />
      </svg>
    </span>
  )
}

export const ContactPhoneIcon = ({
  className = '',
  width = '17',
  height = '17',
}) => {
  return (
    <span className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 11.0312L11.9375 9.71875C11.25 9.40625 10.4688 9.625 10 10.1875L9.15625 11.2188C7.75 10.4062 6.59375 9.25 5.78125 7.84375L6.8125 7C7.375 6.53125 7.59375 5.75 7.28125 5.09375L5.96875 2C5.65625 1.28125 4.875 0.875 4.09375 1.0625L1.25 1.71875C0.5 1.875 0 2.53125 0 3.3125C0 10.875 6.125 17 13.6875 17C14.4688 17 15.125 16.5 15.2812 15.75L15.9375 12.9062C16.125 12.125 15.7188 11.3438 15 11.0312ZM14.4688 12.5625L13.8125 15.4062C13.8125 15.4688 13.75 15.5 13.6875 15.5C6.96875 15.5 1.46875 10.0312 1.46875 3.3125C1.46875 3.25 1.53125 3.1875 1.59375 3.1875L4.4375 2.53125H4.46875C4.53125 2.53125 4.5625 2.5625 4.59375 2.59375L5.90625 5.65625C5.9375 5.71875 5.9375 5.78125 5.875 5.84375L4.34375 7.0625C4.09375 7.3125 4 7.6875 4.15625 8C5.1875 10.0938 6.90625 11.8125 9 12.8438C9.3125 13 9.71875 12.9062 9.9375 12.6562L11.1875 11.125C11.2188 11.0625 11.2812 11.0625 11.3438 11.0938L14.4062 12.4062C14.4688 12.4375 14.5 12.5 14.4688 12.5625Z"
          fill="#3488A9"
        />
      </svg>
    </span>
  )
}

export const ContactEmailIcon = ({
  className = '',
  width = '16',
  height = '12',
}) => {
  return (
    <span className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0H2C0.875 0 0 0.90625 0 2V10C0 11.125 0.875 12 2 12H14C15.0938 12 16 11.125 16 10V2C16 0.90625 15.0938 0 14 0ZM2 1.5H14C14.25 1.5 14.5 1.75 14.5 2V2.71875L9.28125 7.0625C8.5625 7.65625 7.40625 7.65625 6.6875 7.0625L1.5 2.71875V2C1.5 1.75 1.71875 1.5 2 1.5ZM14 10.5H2C1.71875 10.5 1.5 10.2812 1.5 10V4.65625L5.75 8.21875C6.375 8.71875 7.15625 9.03125 8 9.03125C8.8125 9.03125 9.59375 8.71875 10.2188 8.21875L14.5 4.65625V10C14.5 10.2812 14.25 10.5 14 10.5Z"
          fill="#3488A9"
        />
      </svg>
    </span>
  )
}
