import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import { Divider } from '@/components/divider'
import { H3, P1 } from '@/components/typography'
import Block from '@/layouts/block'
import { StandardCardProps } from '@/components/cards/services/standard'

const JobsCard = ({
  className = '',
  title,
  description,
  buttonText = '',
  buttonPath = '',
}: StandardCardProps) => {
  return (
    <Block className={`${className} jobsCard`}>
      <H3>{title}</H3>
      <P1>{description}</P1>
      <Divider />
      <ButtonLinkPrimaryTextAndArrow className="ml-auto" href={buttonPath}>
        {buttonText}
      </ButtonLinkPrimaryTextAndArrow>
    </Block>
  )
}

export default JobsCard
