import Image from '@/components/image'
import { H3, P1, P2 } from '@/components/typography'
import { useSmallScreen } from '@/hooks/useMediaQuery'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'

interface CTAReviewCardProps {
  className?: string
  title: string
  name: string
  position: string
  image: Omit<Asset, 'sys' | 'contentfulMetadata' | '_id'> | undefined
}

const CTAReviewCard = ({
  className = '',
  title,
  name,
  position,
  image,
}: CTAReviewCardProps) => {
  const isSmallScreen = useSmallScreen()
  return (
    <Block className={`ctaReviewCard ${className}`}>
      {!isSmallScreen && (
        <Image
          className="ctaReviewCard__img"
          src={image?.url ?? ''}
          alt="review-card"
          height={289}
          width={221}
        />
      )}
      <div className="ctaReviewCard__text">
        <H3 className="mb-[20px]">{title}</H3>
        <div className="flex">
          <P2>{name} </P2>
          <P1 className="mx-1">|</P1>
          <P1>{position}</P1>
        </div>
      </div>
    </Block>
  )
}

export default CTAReviewCard
