export const API_ENDPOINTS = {
  CANDIDATE: {
    GET: '/api/candidate/get',
    ADD: '/api/candidate/add',
  },
  AVAILABILITY: {
    GET: '/api/availability/get',
    UPDATE: '/api/availability/update',
  },
  USER: {
    CREATE: '/api/user/create',
  },
  ACCOUNT: {
    CREATE: '/api/account/create',
  },
  AUTH: {
    LOGIN: '/api/auth/login',
    MIGRATE: '/api/auth/migrate',
  },
  PROFILE: {
    UPDATE: '/api/profile/update',
  },
  MATCH_CRITERIA: {
    LIST_ALL: '/api/match-criteria/list-all',
    GET_FILTER: '/api/match-criteria/get/filter',
  },
  VACANCY: {
    APPLY: {
      RVDB: '/api/vacancy/apply/rvdb',
      RAY: '/api/vacancy/apply/ray',
    },
    LIST: '/api/vacancy/list',
    SELECT: '/api/vacancy/select',
    GET: (vid: string) => `/api/vacancy/get/${vid}`,
  },
  DISCOVERY_CHANNELS: {
    LIST: '/api/discovery-channels/list',
  },
  FILES: {
    UPLOAD: '/api/files/upload',
    LIST: '/api/files/list',
    DELETE: (fid: string) => `/api/files/delete/${fid}`,
    TYPE: {
      LIST: '/api/files/type/list',
    },
  },
  PICTURE: {
    GET: '/api/picture/get',
    UPLOAD: '/api/picture/upload',
  },
  EMAIL: {
    APPLY_TO_VACANCY: (email: string, userName: string, vacancyName: string) =>
      `/api/email/apply-to-vacancy/${email}?userName=${userName}&vacancyName=${vacancyName}`,
    RESET_PASSWORD: (email: string) => `/api/email/reset-password/${email}`,
    VERIFY_EMAIL: (email: string) => `/api/email/verify-email/${email}`,
  },
  EELLOO: {
    USER: {
      ADD: '/api/eelloo/user/add',
      ACTIVATE: '/api/eelloo/user/activate',
    },
    BROWSER_TOKEN: {
      GET: '/api/eelloo/browser-token/get',
    },
  },
}
