type ObjType<T> = { [s: string]: T } | ArrayLike<T>

export function removeEmptyArrays(obj: unknown) {
  return Object.fromEntries(
    Object.entries(obj as typeof Object).filter(([, v]) => {
      if (!Array.isArray(v)) return v
      return v.length != 0
    })
  )
}

export function removeEmptyObjects<T>(obj: ObjType<T>) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => {
      if (!v) return
      else if (typeof v !== 'object') return v
      return Object.keys(v).length !== 0
    })
  )
}

export function getKeyByValue<T>(object: Record<string | number, T>, value: T) {
  return Object.keys(object).find(
    (key: string | number) => object[key] === value
  )
}

export function createInitValuesObject<T extends Object>(values: T) {
  return { ...values }
}

export const stringifyDataObj = <T>(data: T) => {
  const stringifiedData = JSON.stringify(data)

  return stringifiedData
}
