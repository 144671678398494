import { VacancyDetail } from '@/interfaces/vacancy'
import {
  getPublicationStatus,
  vacancyPublicationNames,
} from '@/lib/vacancy-status'
import { H6 } from '@/components/typography'

export const VacancyTag = ({
  publicationStatusId,
}: Pick<VacancyDetail, 'publicationStatusId'>) => {
  const { text, colorName } = getPublicationStatus(String(publicationStatusId))

  return (
    <div className="vacancyTag" data-color={colorName}>
      <H6>{text}</H6>
    </div>
  )
}

export const VacancyTagCard = ({
  publicationStatusId,
}: Pick<VacancyDetail, 'publicationStatusId'>) => {
  const { text, colorName } = getPublicationStatus(String(publicationStatusId))

  return (
    <div className="vacancyTagCard" data-color={colorName}>
      <H6>{text}</H6>
    </div>
  )
}
