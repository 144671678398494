import { useRouter } from 'next/router'

import Link from '@/components/link'
import { P1 } from '@/components/typography'
import { useAuth } from '@/hooks/auth'
import { routes } from '@/lib/constants'
import { NavigationItem } from '@/interfaces/schema'

export default function NavSubItem({
  path = '',
  name,
  onClick,
  isActive,
}: Omit<NavigationItem, 'sys' | 'contentfulMetadata' | '_id'> & {
  onClick: (evt: React.SyntheticEvent) => void
  id?: string
  isActive?: boolean
}) {
  return (
    <Link href={path} onClick={onClick}>
      <P1
        className={`mt-[18px] cursor-pointer ${
          isActive ? '!text-ray-primary' : ''
        }`}
      >
        {name}
      </P1>
    </Link>
  )
}

export const NavSubItemLogout = ({
  onClick,
}: {
  onClick: (evt: React.SyntheticEvent) => void
}) => {
  const { push } = useRouter()
  const { logout } = useAuth()

  const handleLogout = async (evt: React.SyntheticEvent): Promise<void> => {
    onClick(evt)
    await push(routes.rvdb.home)
    logout()
  }

  return (
    <button onClick={handleLogout} data-test="logoutButton">
      <P1 className="mt-[18px] cursor-pointer">Uitloggen</P1>
    </button>
  )
}
