import useIsRay from '@/hooks/useIsRay'
import getColor from '@/lib/colors'
import NextNProgress from 'nextjs-progressbar'

const ProgressBar = () => {
  const isRay = useIsRay()
  const color = getColor(isRay)

  return (
    <span className="progressBar">
      <NextNProgress options={{ showSpinner: false }} color={color} />
    </span>
  )
}

export default ProgressBar
