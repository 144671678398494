import { useEffect } from 'react'

import { RvdbLogoHeader } from '@/components/icons'
import Link from '@/components/link'
import Hamburger from '@/components/hamburger'
import { RvdbNavSmallProps } from '@/interfaces/nav'
import RvdbNavSecondarySmall from './secondary-small'
import RvdbNavPrimarySmall from './primary-small'
import SearchBar from '@/components/global-search'
import { routes } from '@/lib/constants'
import { H4 } from '@/components/typography'
import { RayButton } from '@/components/button'

export default function RvdbNavSmall({
  activeMenuItem,
  isNavOpen,
  toggleNav,
  toggleNavItem,
  openNavItem,
  toggleNavAndNavItems,
  primaryItems,
  secondaryItems,
}: RvdbNavSmallProps) {
  useEffect(() => {
    const body = document.querySelector('body')!
    body.style.overflowY = isNavOpen ? 'hidden' : 'auto'
    body.style.position = isNavOpen ? 'fixed' : 'static'
  }, [isNavOpen])

  return (
    <header
      className={`${
        isNavOpen ? '' : 'h-[50px]'
      } w-full fixed top-0 left-0 z-30 bg-white`}
    >
      <div className="px-[15px]">
        <div className="flex items-center justify-between w-full h-[50px]">
          <Link href={routes.rvdb.home}>
            <RvdbLogoHeader width="82" height="29" />
          </Link>
          <Hamburger
            className="ml-auto"
            isNavOpen={isNavOpen}
            toggleNav={toggleNav}
          />
        </div>
      </div>
      {isNavOpen && (
        <div className="bg-white p-[30px] h-[calc(100vh-50px)] overflow-y-scroll">
          <SearchBar
            className="h-[50px] max-w-full ml-auto place-items-center"
            toggleNavAndNavItems={toggleNavAndNavItems}
          />
          <RvdbNavSecondarySmall
            activeMenuItem={activeMenuItem}
            openNavItem={openNavItem}
            toggleNavAndNavItems={toggleNavAndNavItems}
            toggleNavItem={toggleNavItem}
            secondaryItems={secondaryItems}
          />
          <RvdbNavPrimarySmall
            primaryItems={primaryItems}
            openNavItem={openNavItem}
            toggleNavItem={toggleNavItem}
            toggleNavAndNavItems={toggleNavAndNavItems}
            activeMenuItem={activeMenuItem}
          />
          <div className="pl-4 flex gap-2 items-center mb-20">
            <H4>Log in op</H4> <RayButton href={routes.ray.dashboard} />
          </div>
        </div>
      )}
    </header>
  )
}
