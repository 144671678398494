import { RequestMethod } from '@/interfaces/http'
import fetchData from '@/lib/http/request'
import { API_ENDPOINTS } from '../serverless/api/config'

// Send a reset password email.
export const triggerEmailResetPass = async (email: string) => {
  const url = API_ENDPOINTS.EMAIL.RESET_PASSWORD(email)
  const response = await fetchData<boolean>({ url })

  return response
}

// Send a confirmation email upon a successful application submission
export const triggerEmailConfirmation = async ({
  email,
  userName,
  vacancyName,
  idToken,
}: {
  email: string
  userName: string
  vacancyName: string
  idToken: string
}) => {
  const url = API_ENDPOINTS.EMAIL.APPLY_TO_VACANCY(email, userName, vacancyName)
  const response = await fetchData<boolean>({
    url,
    idToken,
    method: RequestMethod.GET,
  })

  return response
}

// Verify email address upon signup action.
export const triggerEmailVerification = async ({
  email,
  idToken,
}: {
  email: string
  idToken: string
}) => {
  const url = API_ENDPOINTS.EMAIL.VERIFY_EMAIL(email)
  const response = await fetchData<boolean>({ url, idToken })

  return response
}
