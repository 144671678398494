import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'

import MuiPalette from '@/components/mui-theme'

export default function CircularLoader({
  color = 'inherit',
  size = 25,
}: {
  size?: number
  color?: CircularProgressProps['color']
}) {
  return (
    <MuiPalette>
      <CircularProgress size={size} color={color} />
    </MuiPalette>
  )
}
