import { OtysVacancyDetailPairs } from '@/interfaces/vacancy'
import {
  advancedPublishedStatusId,
  modifiedProfileStatusId,
  newPublishedStatusId,
  onHoldPublishedStatusId,
  openApplicationStatusId,
  openVacancyStatusId,
  urgentStatusId,
} from './environment'

export const checkVacancyIsPublic = (
  vacancy: OtysVacancyDetailPairs
): boolean => {
  const isPublishedVacancy = vacancy?.published
  const isOpenVacancy = vacancy?.statusId == openVacancyStatusId // doing loose equality check here because the values might be strings or numbers

  return isPublishedVacancy && isOpenVacancy
}

export const vacancyPublicationNames = {
  [newPublishedStatusId]: { text: 'Nieuw', colorName: 'new' },
  [onHoldPublishedStatusId]: { text: 'On Hold', colorName: 'onHold' },
  [advancedPublishedStatusId]: {
    text: 'Vergevorderd Stadium',
    colorName: 'advancedStage',
  },
  [openApplicationStatusId]: {
    text: 'Open Inschrijving',
    colorName: 'openApplication',
  },
  [modifiedProfileStatusId]: {
    text: 'Gewijzigd Profiel',
    colorName: 'modifiedProfile',
  },
  [urgentStatusId]: { text: 'Spoed', colorName: 'urgent' },
}

export const getPublicationStatus = (
  publicationStatusId: string
): { text: string; colorName: string } => {
  const mapping = vacancyPublicationNames[publicationStatusId]
  if (mapping) return mapping
  return { text: 'Unknown status', colorName: '' }
}
