import { SyntheticEvent } from 'react'
import { useRouter } from 'next/router'

import { P1, P2 } from '@/components/typography'
import { useSmallScreen } from '@/hooks/useMediaQuery'
import { SearchResultCardProps } from '@/interfaces/search'
import Block from '@/layouts/block'

export const SearchResultCard = ({
  title,
  type,
  route,
  closeSearch,
  toggleNavAndNavItems,
}: SearchResultCardProps) => {
  const { push } = useRouter()
  const isSmallScreen = useSmallScreen()

  const onClick = (path: string) => (evt: SyntheticEvent) => {
    evt.preventDefault()
    push(path)
    closeSearch()
    toggleNavAndNavItems && toggleNavAndNavItems()
  }

  return (
    <Block className="searchResultCard">
      <button onClick={onClick(route)}>
        <P2>{title}</P2>
        {type && <P1>{type}</P1>}
      </button>
    </Block>
  )
}

export default SearchResultCard
