import { AngleDownIcon, AngleUpIcon } from '@/components/icons'
import { Li } from '@/components/typography'
import {
  NavItemLargeWithSubItemsProps,
  RayNavigationItem,
} from '@/interfaces/nav'
import NavSubItem, { NavSubItemLogout } from '../shared/sub-item'

export default function NavItemSmallWithSubItemsRay({
  id,
  name,
  subItems,
  IconComponent,
  toggleNavItem,
  toggleNavAndNavItems,
  isNavItemOpen,
  isActive,
  activeMenuItem
}: NavItemLargeWithSubItemsProps) {
  return (
    <div className="pb-10 flex flex-col items-start">
      <div className="flex items-center">
        {IconComponent && IconComponent}
        <button className="flex items-center" onClick={toggleNavItem} id={id}>
          <Li className={`pl-6 pr-2 ${isActive ? '!text-ray-primary' : ''}`}>
            {name}
          </Li>
          {isNavItemOpen ? <AngleUpIcon /> : <AngleDownIcon />}
        </button>
      </div>
      {isNavItemOpen && (
        <div className="flex flex-col pl-[50px]">
          {((subItems as RayNavigationItem[]) || []).map(
            ({ id, path, name = '' }) => {
              return name === 'Uitloggen' ? (
                <NavSubItemLogout key={id} onClick={toggleNavAndNavItems} />
              ) : (
                <NavSubItem
                  id={id}
                  key={id}
                  path={path}
                  name={name}
                  onClick={toggleNavAndNavItems}
                  isActive={path === activeMenuItem}
                />
              )
            }
          )}
        </div>
      )}
    </div>
  )
}
