import {
  UserIcon,
  DashboardIcon,
  BooksIcon,
  BriefcaseIcon,
  CalenderIcon,
} from '@/components/icons'
import { RayNavigationItem } from '@/interfaces/nav'
import { routes } from '@/lib/constants'

export const navItems: RayNavigationItem[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    path: routes.ray.dashboard,
    authRequired: true,
    IconComponent: <DashboardIcon />,
    subItems: null,
  },
  {
    id: 'mijn-profiel',
    name: 'Mijn profiel',
    path: routes.ray.my_profile.profile,
    authRequired: true,
    IconComponent: <UserIcon />,
    subItems: [
      { id: 'profiel', name: 'Profiel', path: routes.ray.my_profile.profile },
      { id: 'account', name: 'Account', path: routes.ray.my_profile.account },
      { id: 'uitloggen', name: 'Uitloggen', path: '' },
    ],
  },
  {
    id: 'vacatures',
    name: 'Vacatures',
    path: routes.ray.vacancies,
    authRequired: true,
    IconComponent: <BriefcaseIcon />,
    subItems: null,
  },
  {
    id: 'kennis-en-inspiratie',
    name: 'Kennis en inspiratie',
    path: routes.ray.knowledgeAndInspiration,
    authRequired: true,
    IconComponent: <CalenderIcon />,
    subItems: null,
  },
  {
    id: 'events-en-trainingen',
    name: 'Trainingen & Events',
    path: routes.ray.events,
    authRequired: true,
    subItems: null
  }
]
