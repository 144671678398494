import WhiteSpace from '@/components/white-space'
import { ReactNode } from 'react'

export default function renderWhiteSpace(children: ReactNode | ReactNode[]) {
  if (Array.isArray(children) && children.length === 1 && children[0] === '') {
    return <WhiteSpace />
  }

  return children
}
