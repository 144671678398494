import { useState } from 'react'
import { useRouter } from 'next/router'

import { useAuth } from '@/hooks/auth'
import { useMediumScreen } from '@/hooks/useMediaQuery'
import Nav from '@/layouts/nav/ray'

export default function Header() {
  const { pathname } = useRouter()
  const { isAuthenticated } = useAuth()
  const isMediumScreen = useMediumScreen()

  const [isNavOpen, setNavOpen] = useState(false)
  const [isNavItemOpen, setNavItemOpen] = useState(false)

  const toggleNav = () => setNavOpen(!isNavOpen)
  const toggleNavItem = () => setNavItemOpen(!isNavItemOpen)
  const toggleNavAndNavItems = () => {
    setNavOpen(!isNavOpen)
    setNavItemOpen(!isNavItemOpen)
  }

  return (
    <Nav
      activeMenuItem={pathname}
      isAuthenticated={isAuthenticated}
      isMediumScreen={isMediumScreen}
      isNavOpen={isNavOpen}
      isNavItemOpen={isNavItemOpen}
      toggleNav={toggleNav}
      toggleNavItem={toggleNavItem}
      toggleNavAndNavItems={toggleNavAndNavItems}
    />
  )
}
