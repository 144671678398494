import React, { ErrorInfo, ReactNode } from 'react'
import ErrorFallback from './fallback'
import Template from '@/layouts/template'

interface Props {
  children: ReactNode
  header: ReactNode
  footer: ReactNode
}

interface State {
  hasError: boolean
  errorMessage: string
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { hasError: false, errorMessage: '' }

    this.resetError = this.resetError.bind(this)
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      errorMessage: 'Er gaat wat mis. Stay cool, blijf bij ons!',
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error({ error, errorInfo })
  }

  resetError() {
    this.setState({ hasError: false, errorMessage: '' })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Template>
          {this.props.header}
          <ErrorFallback
            message={this.state.errorMessage}
            resetError={this.resetError}
          />
          {this.props.footer}
        </Template>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
