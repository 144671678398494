import Link from '@/components/link'
import { RvdbLogoHeader } from '@/components/icons'
import { routes } from '@/lib/constants'
import { RvdbNavLargeProps } from '@/interfaces/nav'
import RvdbNavPrimaryLarge from './primary-large'
import RvdbNavSecondaryLarge from './secondary-large'
import { useHeaderHeight } from '@/hooks/useHeaderHeight'
import { useEffect, useState } from 'react'
import useIsScrolled from '@/hooks/useIsScrolled'

export default function RvdbNavLarge({
  toggleNavItem,
  toggleNavAndNavItems,
  activeMenuItem,
  openNavItem,
  primaryItems,
  secondaryItems,
}: RvdbNavLargeProps) {
  const { headerRef } = useHeaderHeight()
  const isScrolled = useIsScrolled()

  return (
    <header ref={headerRef} className="rvdbNavLarge" data-scrolled={isScrolled}>
      <div>
        <RvdbNavPrimaryLarge primaryItems={primaryItems} />
        <Link href={routes.rvdb.home} className="rvdbNavLarge__logo">
          <RvdbLogoHeader />
        </Link>
        <RvdbNavSecondaryLarge
          activeMenuItem={activeMenuItem}
          openNavItem={openNavItem}
          toggleNavAndNavItems={toggleNavAndNavItems}
          toggleNavItem={toggleNavItem}
          secondaryItems={secondaryItems}
        />
      </div>
    </header>
  )
}
