import React, { SyntheticEvent } from 'react'
import { useRouter } from 'next/router'

import { RayNavItemLargeProps } from '@/interfaces/nav'
import Link from '@/components/link'
import { Li } from '@/components/typography'

export default function NavItemLargeRay({
  id,
  name,
  path = '',
  isActive,
  IconComponent,
  toggleNavAndNavItems,
  isNavItemOpen,
}: RayNavItemLargeProps) {
  const { push } = useRouter()

  const closeNav = (evt: SyntheticEvent) => {
    evt.preventDefault()
    isNavItemOpen && toggleNavAndNavItems()
    push(path)
  }

  return (
    <div className="pb-10 lg:pb-0 flex items-center">
      {IconComponent && IconComponent}
      <Link href="" onClick={closeNav}>
        <Li className={`${isActive ? '!text-ray-primary' : ''}`}>{name}</Li>
      </Link>
    </div>
  )
}
