import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import Image from '@/components/image'
import { H3, P1 } from '@/components/typography'
import { useSmallScreen } from '@/hooks/useMediaQuery'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'
import { StandardCardProps } from '../standard'

const OrganizationsCard = ({
  className = '',
  title,
  description,
  buttonText,
  image,
  buttonLink,
}: StandardCardProps & {
  image: Omit<Asset, 'sys' | 'contentfulMetadata' | '_id'> | undefined
  buttonLink: string
}) => {
  const isSmallScreen = useSmallScreen()

  return (
    <Block className={`${className} organizationCard`}>
      <Image
        className="organizationCard__img"
        src={image?.url ?? ''}
        alt="organization-card"
        width={768}
        height={isSmallScreen ? 153 : 432}
      />
      <div className="organizationCard__text">
        <div>
          <H3 className="mb-[20px]">{title}</H3>
          <P1 className="mb-[20px]">{description}</P1>
        </div>
        <ButtonLinkPrimaryTextAndArrow className="ml-auto" href={buttonLink}>
          {buttonText}
        </ButtonLinkPrimaryTextAndArrow>
      </div>
    </Block>
  )
}

export default OrganizationsCard
