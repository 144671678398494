import useIsRay from '@/hooks/useIsRay'
import HeaderRay from '@/layouts/header/ray'
import HeaderRvdb from '@/layouts/header/rvdb'
import { RvdbNavigation } from '@/interfaces/schema'

const Header = ({ data }: { data: RvdbNavigation | null }) => {
  const isRay = useIsRay()

  return isRay ? <HeaderRay /> : <HeaderRvdb data={data} />
}

export default Header
