import { useId } from 'react'

import { RvdbNavSecondaryProps } from '@/interfaces/nav'
import { GRID_ITEM_FULL_WIDTH } from '@/lib/constants'

import RvdbNavItemSmall from './item-small'

const RvdbNavSecondarySmall = ({
  secondaryItems,
  openNavItem,
  toggleNavItem,
  toggleNavAndNavItems,
  activeMenuItem,
}: RvdbNavSecondaryProps) => {
  const id = useId()
  return (
    <div
      className={`${GRID_ITEM_FULL_WIDTH} col-start-1 md:col-end-6 justify-self-start pt-8 pl-4 md:pl-12 flex flex-col`}
    >
      {secondaryItems?.map(
        ({ name, path, icon, navigationSubItemsCollection }, index) => (
          <RvdbNavItemSmall
            key={`${id}-${index}`}
            id={name}
            name={name}
            icon={icon}
            path={path}
            subItems={navigationSubItemsCollection?.items}
            toggleNavItem={toggleNavItem}
            toggleNavAndNavItems={toggleNavAndNavItems}
            openNavItem={openNavItem}
            activeMenuItem={activeMenuItem}
          />
        )
      )}
    </div>
  )
}

export default RvdbNavSecondarySmall
