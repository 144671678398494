import Block from '@/layouts/block'
import { Footer as FooterProps } from '@/interfaces/schema'
import RichTextRenderer from '@/services/contentful/utils/richText'
import Script from 'next/script'

export default function Newsletter({ className = '' }) {
  return (
    <Block className={`${className}`}>
      <h3 className="H2 mb-6 text-[32px] leading-[38px]">
        Schrijf je in voor de nieuwsbrief
      </h3>
      <div className="_form_16"></div>
      <Script
        src="https://rvdb36281.activehosted.com/f/embed.php?id=16"
        type="text/javascript"
      />
    </Block>
  )
}
