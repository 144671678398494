import { H6, H7 } from '@/components/typography'
import Link from '@/components/link'
import Block from '@/layouts/block'
import { RayButton, RvdbButton } from '@/components/button'
import { routes } from '@/lib/constants'
import useIsRay from '@/hooks/useIsRay'

const Links = ({ className = '' }: { className: string }) => {
  const isRay = useIsRay()
  const renderRoutes = isRay ? routes.ray : routes.rvdb

  return (
    <Block className={` ${className}`}>
      <H6>Links</H6>
      <Link href={renderRoutes.faq}>
        <H7>Veel gestelde vragen</H7>
      </Link>
      <Link href={routes.ray.my_profile.profile}>
        <H7>Mijn profiel</H7>
      </Link>
      <Link href={renderRoutes.vacancies}>
        <H7>Vacatures</H7>
      </Link>
      {isRay ? (
        <div className="w-full flex items-center mt-6">
          <RvdbButton href={routes.rvdb.home} />
        </div>
      ) : (
        <div className="w-full flex items-center gap-3 m-6">
          <H7>Log in op:</H7>
          <RayButton href={routes.rvdb.auth.login} />
        </div>
      )}
    </Block>
  )
}

export default Links
