export default {
  title: 'Rvdb',
  description: 'People make progress',
  openGraph: {
    type: 'website',
    locale: 'nl-NL',
    url: 'https://rvdb.nl/',
    site_name: 'Rvdb',
    title: 'Rvdb',
    description: 'People make progress',
    images: [
      {
        url: 'public/logo.png',
        width: 203,
        height: 73,
        alt: 'Rvdb logo',
        type: 'image/png',
      },
    ],
  },
}
