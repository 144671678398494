import { CloseIcon, HamburgerIcon } from '../icons'

type Props = {
  isNavOpen: boolean
  toggleNav: () => void
  className?: string
}

const Hamburger = ({ isNavOpen, toggleNav, className = '' }: Props) => (
  <button className={`flex items-center ${className}`} onClick={toggleNav}>
    {isNavOpen ? <CloseIcon /> : <HamburgerIcon />}
  </button>
)

export default Hamburger
