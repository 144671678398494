import axios, { AxiosError } from 'axios'

import {
  HttpErrorResponseObj,
  HttpSuccessResponseObj,
  RequestMethod,
} from '@/interfaces/http'
import { FileTypeAsOption, FileTypes } from '@/interfaces/files'
import { VacanciesSelectionId, VacancyDetailsCard } from '@/interfaces/vacancy'
import { DiscoveryChannel } from '@/interfaces/discovery-channels'
import { SelectOptionType } from '@/components/select'

export interface FetcherProps<DataType> {
  url: string
  idToken?: string
  method?: RequestMethod
  data?: DataType
}

export function getFetchError(e: unknown, url: string): Error {
  const axiosError = e as AxiosError<HttpErrorResponseObj>
  const httpError = axiosError?.response?.data?.result?.error as Error

  // Runtime error
  if (!httpError) {
    const error = e as Error
    const name = error?.name ?? 'fetch-data-error'
    const message =
      error?.message ?? `Failed to fetch resources from this URL: ${url}`

    return { ...error, name, message } as Error
  }

  return httpError
}

export default async function fetchData<ReturnedType, PayloadType = undefined>({
  url,
  idToken,
  method = RequestMethod.GET,
  data,
}: FetcherProps<PayloadType>) {
  try {
    const headersWithAuth = {
      Authorization: `Bearer ${idToken ?? ''}`,
      accept: 'application/json',
    }

    const response = await axios.request<HttpSuccessResponseObj<ReturnedType>>({
      method,
      url,
      data,
      headers: idToken ? headersWithAuth : { accept: 'application/json' },
    })

    const result = response.data.result.data
    return result
  } catch (e) {
    const error = getFetchError(e, url)
    throw error
  }
}

export async function fetchFileTypes({
  url,
  idToken,
}: {
  url: string
  idToken?: string
}) {
  try {
    const data = await fetchData<FileTypes>({ url, idToken })

    const dataAsOptions: FileTypeAsOption[] = Object.values(data)
      .filter((v) => v !== 'CV')
      .map((v) => {
        return {
          label: v,
          value: v,
        }
      })

    return dataAsOptions
  } catch (e) {
    const error = getFetchError(e, url)
    throw error
  }
}

export const fetchVacanciesSelection =
  (body: VacanciesSelectionId) =>
  async ({ url, idToken }: { url: string; idToken?: string }) => {
    try {
      const data = await fetchData<VacancyDetailsCard[], VacanciesSelectionId>({
        url,
        idToken,
        data: body,
        method: RequestMethod.POST,
      })

      return data
    } catch (e) {
      const error = getFetchError(e, url)
      throw error
    }
  }

export const fetchDiscoveryChannels = async ({
  url,
  idToken,
}: {
  url: string
  idToken?: string
}) => {
  try {
    const data = await fetchData<DiscoveryChannel[]>({ url, idToken })

    const dataAsOptions: SelectOptionType[] = data.map((v) => {
      return {
        label: v.value,
        value: v.uid,
      }
    })

    return dataAsOptions
    return []
  } catch (e) {
    const error = getFetchError(e, url)
    throw error
  }
}
