import { ButtonLinkText } from '@/components/button'
import Image from '@/components/image'
import { H2, P1 } from '@/components/typography'
import { ContentTypeLocation } from '@/interfaces/schema'
import Block from '@/layouts/block'

type Props = Omit<ContentTypeLocation, 'sys' | 'contentfulMetadata' | '_id'> & {
  className?: string
}

const LocationCard = ({
  className = '',
  image,
  title,
  addressStreet,
  addressPostalCodeCity,
  mapLocation,
}: Props) => {
  return (
    <Block className={`locationCard ${className}`}>
      <Image
        className="locationCard__img"
        objectPosition="top"
        src={image?.url ?? ''}
        alt="location-card"
        layout="fill"
        width={454}
        height={300}
      />
      <div className="locationCard__text">
        <H2>{title}</H2>
        <P1>{addressStreet}</P1>
        <span className="flex items-center gap-2">
          <P1>{addressPostalCodeCity}</P1>
          <ButtonLinkText href={mapLocation} external className="!h-fit">
            (ROUTE)
          </ButtonLinkText>
        </span>
      </div>
    </Block>
  )
}

export default LocationCard
