/* eslint-disable no-console */
import { Divider, DividerColors } from '@/components/divider'
import { H3, P1 } from '@/components/typography'
import { NewsletterCard } from '@/interfaces/schema'
import Block from '@/layouts/block'
import RichTextRenderer from '@/services/contentful/utils/richText'

type CTANewsLetterCardProps = Pick<
  NewsletterCard,
  'title' | 'description' | 'newsletterCode'
> & { className?: string }

const CTANewsLetterCard = ({
  className = '',
  title = '',
  description = '',
  newsletterCode,
}: CTANewsLetterCardProps) => {
  return (
    <Block className={`ctaNewsLetterCard ${className}`}>
      <H3 className="!text-neutral-white">{title}</H3>
      <P1 className="!text-neutral-white">{description}</P1>
      <Divider color={DividerColors.WHITE} className="!mt-auto" />
      <RichTextRenderer
        className="ctaNewsLetterCard__form"
        richText={newsletterCode}
      />
    </Block>
  )
}

export default CTANewsLetterCard
