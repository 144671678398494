import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import Image from '@/components/image'
import { P1, P2 } from '@/components/typography'
import { useSmallScreen } from '@/hooks/useMediaQuery'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'
import { useId } from 'react'

interface CTARayCardProps {
  className?: string
  title: string
  benefitList: string[]
  buttonText: string
  buttonLink: string
  image: Omit<Asset, 'sys' | 'contentfulMetadata' | '_id'> | undefined
}

const CTARayCardSingleBenefit = ({ text }: { text: string }) => {
  return (
    <div className="flex mb-[20px]">
      <P2 className="!text-ray-primary mr-[10px] ">➔</P2>
      <P1>{text}</P1>
    </div>
  )
}

const CTARayCard = ({
  className = '',
  title,
  benefitList,
  buttonText,
  buttonLink,
  image,
}: CTARayCardProps) => {
  const benefitId = useId()
  const isSmallSCreen = useSmallScreen()

  if (isSmallSCreen) return <></>

  return (
    <Block className={`ctaRayCard ${className}`}>
      <div className="ctaRayCard__text">
        <h3 className="Q1 Q1--ray mb-[50px]">{title}</h3>
        {benefitList.map((benefit, index) => (
          <CTARayCardSingleBenefit
            key={`${benefitId}-${index}`}
            text={benefit}
          />
        ))}
        <ButtonLinkPrimaryTextAndArrow href={buttonLink ?? ''}>
          <P2 className="!text-neutral-white">{buttonText}</P2>
        </ButtonLinkPrimaryTextAndArrow>
      </div>
      <Image
        className="ctaRayCard__img"
        src={image?.url ?? ''}
        alt="profile-card"
        height="531px"
        width="288px"
      />
    </Block>
  )
}

export default CTARayCard
