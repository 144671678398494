import { CSSProperties } from 'react'

export default function Section({
  children,
  className = '',
  id = '',
  styles,
}: {
  children: React.ReactNode
  className?: string
  id?: string
  styles?: CSSProperties
}) {
  return (
    <section id={id} className={`section ${className}`} style={styles}>
      {children}
    </section>
  )
}
