import React, { ReactNode, MouseEvent } from 'react'

import Link from '@/components/link'
import { ButtonVariants, ButtonType, LinkType } from '@/interfaces/button'
import {
  RvdbButtonIcon,
  RayButtonIcon,
  AngleDownIcon,
  ArrowRightIconBig,
  DotIcon,
  AddIcon,
  RayLogoHeader,
  RvdbLogoFooter,
  UploadIcon,
  EyeSlashIcon,
  EyeIcon,
  CloseIcon,
  SearchIcon,
} from '@/components/icons'
import { routes } from '@/lib/constants'
import { DeleteIcon } from '../icons/delete'

const partiallyApplied = <
  P extends Partial<T>,
  T extends { className?: string }
>(
  Component: React.FC<T & unknown>,
  partialProps?: P
) => {
  return function ExtendedComponent(props: T) {
    const propsClassName = (props?.className as unknown as keyof T) ?? ''
    const partialPropsClassName =
      (partialProps?.className as unknown as keyof T) ?? ''
    const cn = `${String(propsClassName)} ${String(partialPropsClassName)}`

    return <Component {...props} {...partialProps} className={cn} />
  }
}

// Buttons come in four variants: primary, secondary, tertiary, quartiary
// Each variant has three versions: text, text with arrow, only arrow
// Each variant also has the version prop, which causes different colors to render
const Button = <T,>({
  variant = ButtonVariants.PRIMARY,
  version = 'a',
  render,
  children,
  onClick,
  className = '',
  id = '',
  type = 'button',
  formId,
  isDisabled = false,
  ...props
}: ButtonType<T>) => (
  <button
    onClick={onClick ?? undefined}
    type={type}
    form={formId || undefined}
    id={id}
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    className={`button button__${variant} button__${variant}--${version} ${className}`}
    disabled={isDisabled}
  >
    {render?.({ children }) ?? children}
  </button>
)

export default Button

export const ButtonLink = <T,>({
  variant = ButtonVariants.PRIMARY,
  version = 'a',
  render,
  children,
  className = '',
  href = '',
  external,
  isDisabled,
  as,
  onClick = undefined,
  ...props
}: LinkType<T>) => {
  const handleClick = (event: MouseEvent<any>) => {
    // default behavior
    if (onClick) return onClick(event)

    // handle smooth scroll when clicking on a hash link, taking into account the offSet of the nav
    if (!event.defaultPrevented && href.startsWith('#')) {
      event.preventDefault()

      const targetId = href.slice(1) // Remove the '#' from href
      const targetElement = document.getElementById(targetId)

      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <Link
      href={href}
      onClick={handleClick}
      external={external}
      className={`button button__${variant}--${version} ${className}`}
      isDisabled={isDisabled}
      as={as}
      {...props}
    >
      {render?.({ children }) ?? children}
    </Link>
  )
}

export const ButtonPrimary = partiallyApplied(Button, {
  variant: ButtonVariants.PRIMARY,
})

export const ButtonLinkPrimary = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.PRIMARY,
})

export const ButtonPrimaryTextAndArrow = partiallyApplied(Button, {
  variant: ButtonVariants.PRIMARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonLinkPrimaryTextAndArrow = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.PRIMARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonPrimaryArrow = partiallyApplied(Button, {
  children: <ArrowRightIconBig />,
  variant: ButtonVariants.PRIMARY,
  className: '!rounded-[50%] !w-[40px] !h-[40px] md:!w-[50px] md:!h-[50px]',
})

export const ButtonLinkPrimaryArrow = partiallyApplied(ButtonLink, {
  children: <ArrowRightIconBig />,
  variant: ButtonVariants.PRIMARY,
  className: '!rounded-[50%] !w-[40px] !h-[40px] md:!w-[50px] md:!h-[50px]',
})

export const ButtonSecondary = partiallyApplied(Button, {
  variant: ButtonVariants.SECONDARY,
})

export const ButtonSecondaryTextAndArrow = partiallyApplied(Button, {
  variant: ButtonVariants.SECONDARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonLinkSecondaryTextAndArrow = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.SECONDARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonSecondaryArrow = partiallyApplied(Button, {
  children: <ArrowRightIconBig />,
  variant: ButtonVariants.SECONDARY,
  className: '!rounded-[50%] !w-[40px] !h-[40px] md:!w-[50px] md:!h-[50px]',
})

export const ButtonLinkSecondaryArrow = partiallyApplied(ButtonLink, {
  children: <ArrowRightIconBig />,
  variant: ButtonVariants.SECONDARY,
  className: '!rounded-[50%] !w-[40px] !h-[40px] md:!w-[50px] md:!h-[50px]',
})

export const ButtonTertiary = partiallyApplied(Button, {
  variant: ButtonVariants.TERTIARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonLinkTertiary = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.TERTIARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonTertiaryReverse = partiallyApplied(Button, {
  variant: ButtonVariants.TERTIARY,
  render: ({ children }) => (
    <span className="button__with-arrow flex flex-row-reverse">
      {children}
      <ArrowRightIconBig className="rotate-180" />
    </span>
  ),
})

export const ButtonLinkTertiaryReverse = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.TERTIARY,
  render: ({ children }) => (
    <span className="button__with-arrow flex flex-row-reverse">
      {children}
      <ArrowRightIconBig className="rotate-180" />
    </span>
  ),
})

export const ButtonQuartiary = partiallyApplied(Button, {
  variant: ButtonVariants.QUARTIARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonLinkQuartiary = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.QUARTIARY,
  render: ({ children }) => (
    <span className="button__with-arrow">
      {children}
      <ArrowRightIconBig />
    </span>
  ),
})

export const ButtonText = partiallyApplied(Button, {
  variant: ButtonVariants.TEXT,
})

export const ButtonLinkText = partiallyApplied(ButtonLink, {
  variant: ButtonVariants.TEXT,
})

export const AngleDownButton = partiallyApplied(Button, {
  variant: ButtonVariants.ANGLE,
  children: <AngleDownIcon />,
  className: '!rounded-[50%] !w-[40px] !h-[40px]',
})

export const AngleUpButton = partiallyApplied(Button, {
  variant: ButtonVariants.ANGLE,
  children: <AngleDownIcon />,
  className: '!rounded-[50%] !w-[40px] !h-[40px] rotate-180',
})

export const CircleDownButton = partiallyApplied(Button, {
  variant: ButtonVariants.ICON,
  children: <AngleDownIcon />,
  className: '!rounded-[50%] !w-[40px] !h-[40px]',
})

export const CircleUpButton = partiallyApplied(Button, {
  variant: ButtonVariants.ICON,
  children: <AngleDownIcon />,
  className: '!rounded-[50%] !w-[40px] !h-[40px] rotate-180',
})

export const CircleAddButton = partiallyApplied(Button, {
  variant: ButtonVariants.ICON,
  children: <AddIcon />,
  className: '!rounded-[50%] !w-[40px] !h-[40px]',
})

export const CircleUploadButton = partiallyApplied(Button, {
  variant: ButtonVariants.ICON,
  children: <UploadIcon />,
  className: '!rounded-[50%] !w-[40px] !h-[40px]',
})

export const DeleteButton = partiallyApplied(Button, {
  variant: ButtonVariants.ICON,
  children: <DeleteIcon />,
  className: '!rounded-[50%] !border-none',
})

export const LoadMoreButton = partiallyApplied(Button, {
  variant: ButtonVariants.LOAD_MORE,
})

// Brand buttons
export const RayButton = partiallyApplied(ButtonLink, {
  children: <RayButtonIcon />,
  variant: ButtonVariants.RAY,
})

export const RvdbButton = partiallyApplied(ButtonLink, {
  children: <RvdbButtonIcon />,
  variant: ButtonVariants.RVDB,
})

export const DotButton = ({
  className = '',
  onClick,
  isActive,
}: {
  onClick: () => void
  className?: string
  isActive: boolean
}) => (
  <button
    className={`${
      isActive ? 'dotButton--active' : 'dotButton--inactive'
    } ${className}`}
    onClick={onClick}
  >
    <DotIcon />
  </button>
)

export const VacancyButton = ({
  isActive,
  onClick,
  children,
}: {
  isActive: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  children: string | ReactNode
}) => {
  const state = isActive ? 'active' : 'inactive'

  return (
    <button
      onClick={onClick}
      className={`vacancyButton vacancyButton--${state}`}
    >
      {children}
    </button>
  )
}

export const ShowPasswordButton = ({
  className = '',
  onClick,
  disabled,
  showPassword,
}: {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled: boolean
  showPassword: boolean
}) => (
  <button
    className={`showPasswordButton ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
  </button>
)

export const CloseButton = partiallyApplied(Button, {
  variant: ButtonVariants.PLAIN,
  children: <CloseIcon />,
})

export const SearchButton = partiallyApplied(Button, {
  variant: ButtonVariants.PLAIN,
  children: <SearchIcon />,
})

export const RayLogoWithLink = () => (
  <Link href={routes.ray.dashboard}>
    <RayLogoHeader />
  </Link>
)
export const RvdbLogoWithLink = () => (
  <Link href={routes.rvdb.home}>
    <RvdbLogoFooter />
  </Link>
)
