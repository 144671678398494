export const EyeIcon = ({ width = '21', height = '17', className = '' }) => {
  return (
    <span className={`${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8594 7.65234C18.9609 3.32812 15.1641 0.375 10.875 0.375C6.55078 0.375 2.75391 3.32812 0.855469 7.65234C0.785156 7.82812 0.75 8.10938 0.75 8.25C0.75 8.42578 0.785156 8.70703 0.855469 8.88281C2.75391 13.207 6.55078 16.125 10.875 16.125C15.1641 16.125 18.9609 13.207 20.8594 8.88281C20.9297 8.70703 21 8.42578 21 8.25C21 8.10938 20.9297 7.82812 20.8594 7.65234ZM15.9375 8.28516C15.9375 11.0625 13.6523 13.3125 10.875 13.3125C8.0625 13.3125 5.8125 11.0625 5.8125 8.25C5.8125 5.47266 8.0625 3.1875 10.875 3.1875C13.6523 3.1875 15.9375 5.47266 15.9375 8.25V8.28516ZM10.875 4.875H10.8398C10.7695 4.91016 10.6641 4.91016 10.5586 4.91016C10.7344 5.26172 10.875 5.61328 10.875 6C10.875 7.26562 9.85547 8.25 8.625 8.25C8.20312 8.25 7.81641 8.14453 7.5 7.96875C7.5 8.07422 7.5 8.17969 7.5 8.25C7.5 10.1133 9.01172 11.625 10.875 11.625C12.7031 11.625 14.25 10.1133 14.25 8.25C14.25 6.42188 12.7031 4.875 10.875 4.875Z"
          fill="#464646"
        />
      </svg>
    </span>
  )
}
