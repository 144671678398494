export const LocationIcon = ({
  className = '',
  width = '18',
  height = '19',
}) => {
  return (
    <span className={`arrow__right-small ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.75 0.25C2.98828 0.25 0 3.27344 0 7C0 9.74219 0.914062 10.5156 6.04688 17.8984C6.22266 18.1445 6.46875 18.25 6.75 18.25C6.99609 18.25 7.24219 18.1445 7.41797 17.8984C12.5508 10.5156 13.5 9.74219 13.5 7C13.5 3.27344 10.4766 0.25 6.75 0.25ZM6.75 15.9297C6.11719 15.0508 5.55469 14.2422 5.0625 13.5742C2.00391 9.21484 1.6875 8.65234 1.6875 7C1.6875 4.22266 3.9375 1.9375 6.75 1.9375C9.52734 1.9375 11.8125 4.22266 11.8125 7C11.8125 8.65234 11.4609 9.21484 8.40234 13.5742C7.91016 14.2422 7.34766 15.0508 6.75 15.9297Z"
          fill="#464646"
        />
      </svg>
    </span>
  )
}
