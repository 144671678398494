import { H7 } from '@/components/typography'

const CopyRights = ({ className = '' }) => {
  return (
    <span className={`inline-flex ${className}`}>
      <H7>Copyright © {new Date().getFullYear()}. </H7>
    </span>
  )
}

export default CopyRights
