export const DeleteIcon = ({ className = '', width = '40', height = '40' }) => {
  return (
    <span className={`deleteIcon ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#FF6612" />
        <path
          d="M27.3125 14.5625H24.3945L23.1992 12.5938C22.8828 12.0664 22.3555 11.75 21.7578 11.75H18.207C17.6094 11.75 17.082 12.0664 16.7656 12.5938L15.5703 14.5625H12.6875C12.3711 14.5625 12.125 14.8438 12.125 15.125V15.6875C12.125 16.0039 12.3711 16.25 12.6875 16.25H13.25V27.5C13.25 28.7656 14.2344 29.75 15.5 29.75H24.5C25.7305 29.75 26.75 28.7656 26.75 27.5V16.25H27.3125C27.5938 16.25 27.875 16.0039 27.875 15.6875V15.125C27.875 14.8438 27.5938 14.5625 27.3125 14.5625ZM18.1367 13.543C18.1719 13.5078 18.2422 13.4375 18.3477 13.4375H21.6523C21.7227 13.4375 21.793 13.5078 21.8281 13.543L22.4258 14.5625H17.5391L18.1367 13.543ZM24.5 28.0625H15.5C15.1836 28.0625 14.9375 27.8164 14.9375 27.5V16.25H25.0625V27.5C25.0625 27.8164 24.7812 28.0625 24.5 28.0625ZM20 26.375C20.2812 26.375 20.5625 26.1289 20.5625 25.8125V18.5C20.5625 18.2188 20.2812 17.9375 20 17.9375C19.6836 17.9375 19.4375 18.2188 19.4375 18.5V25.8125C19.4375 26.1289 19.6836 26.375 20 26.375ZM17.1875 26.375C17.4688 26.375 17.75 26.1289 17.75 25.8125V18.5C17.75 18.2188 17.4688 17.9375 17.1875 17.9375C16.8711 17.9375 16.625 18.2188 16.625 18.5V25.8125C16.625 26.1289 16.8711 26.375 17.1875 26.375ZM22.8125 26.375C23.0938 26.375 23.375 26.1289 23.375 25.8125V18.5C23.375 18.2188 23.0938 17.9375 22.8125 17.9375C22.4961 17.9375 22.25 18.2188 22.25 18.5V25.8125C22.25 26.1289 22.4961 26.375 22.8125 26.375Z"
          fill="white"
        />
      </svg>
    </span>
  )
}
