import React from 'react'
import { default as NextImage, ImageProps } from 'next/image'

interface Props extends ImageProps {
  className?: string
}

const Image = ({
  className = '',
  src,
  alt = '',
  width,
  height,
  objectFit = 'cover',
  objectPosition = 'center',
  placeholder,
  blurDataURL,
  quality,
  unoptimized,
  priority,
  loader,
  onError,
}: Props) => {
  return (
    <div className={`imageWrapper ${className}`}>
      <NextImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        objectFit={objectFit}
        objectPosition={objectPosition}
        placeholder={placeholder}
        blurDataURL={blurDataURL}
        quality={quality}
        unoptimized={unoptimized}
        priority={priority}
        loader={loader}
        onError={onError}
      />
    </div>
  )
}

export default Image
