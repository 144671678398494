import { Fragment } from 'react'

import { AngleDownIcon, AngleUpIcon } from '@/components/icons'
import Image from '@/components/image'
import { Li } from '@/components/typography'
import {
  RvdbNavItemSmallWithSubProps,
  RvdbNavItemSmallProps,
  RvdbNavItemSmallWithoutSubProps,
} from '@/interfaces/nav'
import NavSubItem from '../shared/sub-item'
import Link from '@/components/link'

const RvdbNavItemSmallWithoutSub = ({
  icon,
  path = '',
  name,
  isActive,
  toggleNavAndNavItems,
}: RvdbNavItemSmallWithoutSubProps) => {
  return (
    <div className="rvdbNavItemSmallWithoutSub">
      {icon?.url && (
        <Image
          className="rvdbNavItemSmallWithoutSub__img"
          src={icon?.url}
          height={30}
          width={25}
          objectFit="contain"
          alt=""
        />
      )}
      <Link
        className="rvdbNavItemSmallWithoutSub__link"
        href={path}
        onClick={toggleNavAndNavItems}
      >
        <Li className={`pl-6 lg:pb-0 ${isActive ? '!text-rvdb-primary' : ''}`}>
          {name}
        </Li>
      </Link>
    </div>
  )
}

const RvdbNavItemSmallWithSub = ({
  id,
  name,
  icon,
  subItems,
  toggleNavItem,
  toggleNavAndNavItems,
  isNavItemOpen,
}: RvdbNavItemSmallWithSubProps) => {
  return (
    <div className="pb-10 flex flex-col items-start w-full">
      <div className="flex items-center w-full">
        <Image
          src={icon?.url ?? ''}
          height={30}
          width={25}
          objectFit="contain"
          alt=""
        />
        <button
          className="flex items-center w-full justify-between xs:justify-start"
          onClick={toggleNavItem}
          id={id}
        >
          <Li className="pl-6 pr-2 !text-primary-rvdb inline-block">{name}</Li>
          {isNavItemOpen ? <AngleUpIcon /> : <AngleDownIcon />}
        </button>
      </div>
      {isNavItemOpen && (
        <div className="flex flex-col pl-[50px]">
          {(subItems || []).map(({ path = '', name = '' }, index) => {
            return (
              <NavSubItem
                key={index}
                path={path}
                name={name}
                onClick={toggleNavAndNavItems}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const RvdbNavItemSmall = ({
  id,
  name,
  icon,
  path,
  subItems,
  toggleNavItem,
  toggleNavAndNavItems,
  openNavItem,
  activeMenuItem,
}: RvdbNavItemSmallProps) => {
  return (
    <Fragment>
      {subItems?.length ? (
        <RvdbNavItemSmallWithSub
          id={id}
          name={name}
          subItems={subItems}
          icon={icon}
          toggleNavItem={toggleNavItem}
          toggleNavAndNavItems={toggleNavAndNavItems}
          isNavItemOpen={openNavItem === id}
          isActive={!!subItems?.find((i: any) => i.path === activeMenuItem)}
        />
      ) : (
        <RvdbNavItemSmallWithoutSub
          toggleNavAndNavItems={toggleNavAndNavItems}
          name={name}
          path={path}
          icon={icon}
          isActive={name === activeMenuItem}
        />
      )}
    </Fragment>
  )
}

export default RvdbNavItemSmall
