import { Fragment, useEffect } from 'react'

import { RayLogoWithLink, RvdbButton } from '@/components/button'
import Hamburger from '@/components/hamburger'
import { RayNavigationItem, RayNavSmallScreenProps } from '@/interfaces/nav'
import { routes } from '@/lib/constants'
import { navItems } from './items'
import NavItemSmallWithSubItemsRay from './item-small-with-sub-items'
import { NavItemSmallRay } from './item-small'

export default function NavSmallScreen({
  className = '',
  isAuthenticated,
  activeMenuItem,
  isNavOpen,
  toggleNav,
  toggleNavItem,
  isNavItemOpen,
  toggleNavAndNavItems,
}: Omit<RayNavSmallScreenProps, 'headerRef'>) {
  useEffect(() => {
    const body = document.querySelector('body')!
    body.style.overflowY = isNavOpen ? 'hidden' : 'auto'
    body.style.position = isNavOpen ? 'fixed' : 'static'
  }, [isNavOpen])

  return (
    <header
      className={`${
        isNavOpen ? 'h-full' : 'h-[50px]'
      } w-full fixed top-0 left-0 z-30 bg-white`}
    >
      <div className="px-[15px]">
        <div className="flex items-center justify-between w-full h-[50px]">
          <div className="mr-auto">{<RayLogoWithLink />}</div>
          <div className="ml-auto">
            {isAuthenticated ? (
              <Hamburger isNavOpen={isNavOpen} toggleNav={toggleNav} />
            ) : (
              <RvdbButton href={routes.rvdb.home} />
            )}
          </div>
        </div>
      </div>
      {isNavOpen && (
        <div className={`pl-4 md:pl-12 py-12 ${className}`}>
          {navItems
            .filter((item) => (isAuthenticated ? item : !item.authRequired))
            .map(
              ({
                id,
                name,
                path,
                IconComponent,
                subItems,
              }: RayNavigationItem) => {
                return (
                  <Fragment key={id}>
                    {subItems ? (
                      <NavItemSmallWithSubItemsRay
                        id={id}
                        name={name}
                        subItems={subItems as RayNavigationItem[]}
                        IconComponent={IconComponent}
                        toggleNavItem={toggleNavItem}
                        toggleNavAndNavItems={toggleNavAndNavItems}
                        isNavItemOpen={isNavItemOpen}
                        isActive={
                          !!subItems.find((i) => i.path === activeMenuItem)
                        }
                        activeMenuItem={activeMenuItem}
                      />
                    ) : (
                      <NavItemSmallRay
                        name={name}
                        path={path}
                        IconComponent={IconComponent}
                        toggleNav={toggleNav}
                        isActive={path === activeMenuItem}
                      />
                    )}
                  </Fragment>
                )
              }
            )}
          <div className="pl-4 flex gap-2 items-center">
            <RvdbButton href={routes.rvdb.home} />
          </div>
        </div>
      )}
    </header>
  )
}
