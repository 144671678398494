import { initializeApp, getApps } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectAuthEmulator, getAuth } from 'firebase/auth'

const FirebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
}

let app = null

if (getApps().length === 0) {
  app = initializeApp(FirebaseConfig)
} else {
  app = getApps()[0]
}

const authClient = getAuth(app)
const dbClient = getFirestore()

const host =
  (dbClient.toJSON() as { settings?: { host?: string } }).settings?.host ?? ''

if (process.env.NODE_ENV === 'development' && !host.startsWith('localhost')) {
  connectFirestoreEmulator(dbClient, 'localhost', 8080)
  connectAuthEmulator(authClient, 'http://127.0.0.1:9099')
}

export { authClient, dbClient }
