import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { NextSeo, DefaultSeo } from 'next-seo'
import TagManager from 'react-gtm-module'

import SEO from '../../next-seo.config'
import { AuthContextProvider } from '@/hooks/auth'
import ErrorBoundary from '@/components/error/boundary'
import HeaderRay from '@/layouts/header/ray'
import HeaderRvdb from '@/layouts/header/rvdb'
import Footer from '@/layouts/footer'
import Template from '@/layouts/template'

import { routes } from '@/lib/constants'
import useIsRay from '@/hooks/useIsRay'
import PageProps, { PagePropsWithNav } from '@/interfaces/page'
import {
  Footer as FooterProps,
  MetaInformation,
  RvdbNavigation,
} from '@/interfaces/schema'
import ProgressBar from '@/components/progress-bar'
import '../styles/globals.scss'
import '../styles/main.scss'
import OnrecruitAnalyticsScript from 'src/scripts/onrecruit-analytics'
import Header from '@/layouts/header'
import { HeaderHeightProvider } from '@/hooks/useHeaderHeight'

type Props = AppProps<
  PagePropsWithNav<PageProps<unknown> | null, RvdbNavigation | null>
>

const initPageProps = {
  data: { navData: null, pageData: null },
  error: { navError: null, pageError: null },
  metaInformation: {} as MetaInformation,
}

function MyApp({ Component, pageProps = initPageProps }: Props) {
  const { route } = useRouter()
  const isRay = useIsRay()

  const pagesWithoutLayout = {
    isAuthPage: Object.values(routes.rvdb.auth).includes(route),
    isSollicitatiePage: route.includes('/sollicitatie'),
    isThankYouPage:
      route.includes(routes.rvdb.bedankt) || route.includes(routes.ray.bedankt),
    isSiteMapPage: route.includes('/sitemap'),
  }

  const isPageWithoutLayouts =
    Object.values(pagesWithoutLayout).find((v) => v === true) ?? false

  useEffect(() => {
    // Initialize google tag manager in browser env
    if (process.browser && process.env.NODE_ENV !== 'development') {
      TagManager.initialize({ gtmId: 'GTM-TPBRHC4' })
    }
  }, [])

  if (pagesWithoutLayout.isSiteMapPage) {
    return <Component {...pageProps} />
  }

  // this seems necessary to render 404 page
  if (Object.keys(pageProps).length === 0) return <></>

  const { navData, pageData } = pageProps.data
  const { pageError } = pageProps.error

  const renderHeader = isPageWithoutLayouts ? <></> : <Header data={navData} />

  const renderFooter = isPageWithoutLayouts ? <></> : <Footer />

  const appState = isRay ? 'ray' : 'rvdb'
  const colorsObj = {
    ray: {
      primary: '#ff128e;',
      secondary: '#ff6612',
      tertiary: '#b30d63',
    },
    rvdb: {
      primary: '#09c591',
      secondary: '#3488a9',
      tertiary: '#08a278',
    },
  }

  const metaInformation =
    pageProps?.data?.pageData?.metaInformation ?? ({} as MetaInformation)

  const metaOpenGraphImg = {
    url: metaInformation.image?.url ?? SEO.openGraph.images[0].url,
    alt: metaInformation.image?.description ?? SEO.openGraph.images[0].alt,
    width: metaInformation.image?.width ?? SEO.openGraph.images[0].width,
    height: metaInformation.image?.height ?? SEO.openGraph.images[0].height,
    type: metaInformation.image?.contentType ?? SEO.openGraph.images[0].type,
  }

  const openGraph = {
    ...SEO.openGraph,
    title: metaInformation.title ?? SEO.title,
    description: metaInformation.description ?? SEO.description,
    images: [metaOpenGraphImg],
  }

  const keywords =
    metaInformation.keywordsCollection?.items
      ?.map(({ keyword }) => keyword)
      .join(',') ?? ''

  return (
    <div id="app">
      <style jsx global>{`
        body {
          --color-primary: ${colorsObj[appState].primary};
          --color-secondary: ${colorsObj[appState].secondary};
          --color-tertiary: ${colorsObj[appState].tertiary};
        }
      `}</style>
      <Head>
        <link rel="icon" type="image/png" href={`/icon-${appState}.png`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/icon-${appState}.png`}
        />
      </Head>
      {pagesWithoutLayout.isThankYouPage ? <OnrecruitAnalyticsScript /> : <></>}
      <DefaultSeo {...SEO} />
      <NextSeo
        {...metaInformation}
        openGraph={openGraph}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: keywords,
          },
        ]}
      />
      <ErrorBoundary header={renderHeader} footer={renderFooter}>
        <AuthContextProvider>
          <HeaderHeightProvider isPageWithoutLayouts={isPageWithoutLayouts}>
            <Template>
              <ProgressBar />
              {renderHeader}
              <Component data={pageData} error={pageError} />
              {renderFooter}
            </Template>
          </HeaderHeightProvider>
        </AuthContextProvider>
      </ErrorBoundary>
    </div>
  )
}

export default MyApp
