import { createInitValuesObject } from '../object'

const initLocalStorageData = createInitValuesObject({
  candidateId: '',
  idToken: '',
  email: '',
  firstName: '',
  phoneNumber: '',
  vacancyId: '',
})

export default initLocalStorageData
