import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const RenderHtmlScripts = ({ innerHtml }: { innerHtml: string }) => {
  const scriptType = 'script'

  const parseHtmlScripts = (innerHtml: string) => {
    const scriptOptions: HTMLReactParserOptions = {
      replace: (domNode) => (domNode.type === scriptType ? domNode : <></>),
    }

    return parse(innerHtml, scriptOptions)
  }

  const scripts: JSX.Element[] = parseHtmlScripts(innerHtml) as JSX.Element[]
  const filteredScripts = scripts.filter(
    (el: JSX.Element) => el.type === scriptType
  )

  return filteredScripts.length ? (
    <HelmetProvider>
      <Helmet>{filteredScripts}</Helmet>
      <span dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </HelmetProvider>
  ) : (
    <></>
  )
}

export default RenderHtmlScripts
