import { ReactNode } from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer'

import {
  H1,
  H2,
  H3,
  H4,
  P1,
  H5,
  H6,
  Li,
  Ol,
  Ul,
  Bold,
} from '@/components/typography'
import { ButtonLinkPrimary, ButtonLinkText } from '@/components/button'
import { RichTextRendererProps } from '@/interfaces/richText'
import renderWhiteSpace from '@/lib/white-space'
import RenderHtmlScripts from './renderHtmlScripts'
import Image from '@/components/image'
import InlineVideo from '@/components/video/inline-video'
import ReactVideo from '@/components/video/react-video'
import CodeSnippet from '@/components/code-snippet'

// create a typical ID from the content
// This ID can then be used to scroll towards using anchor links
// remove special characters like punctuation, replace spaces with hyphens, and make it lowercase
// for example "Omzet, imago en creativiteit" becomes "omzet-imago-en-creativiteit"
const createIdFromContent = (children: string[] | ReactNode) => {
  if (Array.isArray(children) && typeof children[0] === 'string') {
    return children[0]
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }

  return ''
}

const findButtonInText = (children: string[] | ReactNode) => {
  // Regular expression to match button syntax: (button buttonName)(slug)
  const regex = /\(button\s+([^)]+)\)\(([^)]+)\)/g

  if (Array.isArray(children) && typeof children[0] === 'string') {
    const match = regex.exec(children[0])

    if (match !== null) {
      const text = match[1]
      let url = match[2]

      // Check if the URL is just a slug, add a slash before it
      if (!url.startsWith('http') && !url.startsWith('/')) {
        url = `/${url}`
      }

      return { text, url }
    }
  }
}

/* 
  Rich text options maps Contentful document content to
  custom components based on their type 
*/
export function getRenderOptions(links: any = null): Options {
  const assetBlockMap = new Map()
  const entryBlockMap = new Map()

  if (links) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    for (const asset of links.assets.block) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      assetBlockMap.set(asset.sys.id, asset)
    }
    for (const entry of links.entries.block) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      entryBlockMap.set(entry.sys.id, entry)
    }
  }

  return {
    renderMark: {
      [MARKS.CODE]: (code) => <RenderHtmlScripts innerHtml={code as string} />,
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <H1 id={createIdFromContent(children)}>{renderWhiteSpace(children)}</H1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <H2 id={createIdFromContent(children)}>{renderWhiteSpace(children)}</H2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <H3 id={createIdFromContent(children)}>{renderWhiteSpace(children)}</H3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <H4 id={createIdFromContent(children)}>{renderWhiteSpace(children)}</H4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <H5 id={createIdFromContent(children)}>{renderWhiteSpace(children)}</H5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <H6 id={createIdFromContent(children)}>{renderWhiteSpace(children)}</H6>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const button = findButtonInText(children)

        if (button) {
          return (
            <ButtonLinkPrimary href={button.url} external className="mb-6">
              {button.text}
            </ButtonLinkPrimary>
          )
        }

        return <P1 className="mb-6">{renderWhiteSpace(children)}</P1>
      },
      [BLOCKS.UL_LIST]: (node, children) => <Ul>{children}</Ul>,
      [BLOCKS.OL_LIST]: (node, children) => <Ol>{children}</Ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <Li>{children}</Li>,
      [INLINES.HYPERLINK]: (node, children) => {
        const isExternal = /^(https?:\/\/|\/\/)/.test(node.data.uri)

        return (
          <ButtonLinkText
            href={node.data.uri ?? ''}
            className="!inline-block !p-0 !m-0 !h-fit !min-h-fit"
            external={isExternal}
          >
            {children}
          </ButtonLinkText>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // embedded assets, including video, pdf, and images
        const asset = assetBlockMap.get(node.data.target.sys.id)
        const isVideo = asset.url.includes('videos')
        const isPDF = asset.contentType === 'application/pdf'

        return isVideo ? (
          <InlineVideo className="mx-auto w-9/12" src={asset.url} />
        ) : isPDF ? (
          <ButtonLinkText href={asset.url} external>
            {asset.title}
          </ButtonLinkText>
        ) : (
          <Image
            {...asset}
            src={asset.url}
            alt={asset.alt || ''}
            column
            objectFit="contain"
          />
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        // embedded entry for youtube videos and code snippets
        const entry = entryBlockMap.get(node.data.target.sys.id)

        if (entry.__typename === 'YoutubeVideo')
          return (
            <ReactVideo
              className="mx-auto !max-w-full !w-[512px] !h-[288px] sm:!w-[480px] sm:!h-[270px] md:!w-[640px] md:!h-[360px]"
              url={entry.youtubeLink}
              controls={true}
              muted={false}
            />
          )

        if (entry.__typename === 'CodeSnippet') {
          return <CodeSnippet code={entry} />
        }
        return <></>
      },
    },
  }
}

// Function that takes CTF rich text document and converts it to React component
export default function RichTextRenderer({
  richText,
  className = '',
}: RichTextRendererProps): JSX.Element {
  if (!richText || !richText.json) return <></>

  return (
    <div className={className ?? null}>
      {documentToReactComponents(
        richText.json,
        getRenderOptions(richText.links)
      )}
    </div>
  )
}
