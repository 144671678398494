import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react'

interface Props {
  children: ReactNode
  style?: CSSProperties
  className?: string
  testId?: string
  id?: string
}

const Block = forwardRef(function Block(
  props: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { className = '', id = '', style, children, testId } = props
  return (
    <div
      className={`block ${className}`}
      id={id}
      style={style}
      ref={ref}
      data-test={testId ?? ''}
    >
      {children}
    </div>
  )
})

export default Block
