import { RayLogoFooter, RvdbLogoFooter } from '@/components/icons'
import { ButtonPrimaryArrow } from '@/components/button'
import CopyRights from '@/layouts/footer/copy-rights'
import Newsletter from '@/layouts/footer/newsletter'
import Address from '@/components/address'
import Links from '@/layouts/footer/links'
import Legal from '@/layouts/footer/legal'
import Block from '@/layouts/block'
import Social from '@/blocks/social'
import useIsRay from '@/hooks/useIsRay'

export default function Footer() {
  return (
    <footer className="footer bg-gradient--reverse">
      <div className="footer__wrapper">
        <Newsletter className="footer__newsletter" />
        <FooterLogo className="footer__logo" />
        <ContactDetails className="footer__contact-details" />
        <Links className="footer__links" />
        <Legal className="footer__legal" />
        <div className="footer__scroll-up">
          <ButtonPrimaryArrow
            className="rotate-270"
            onClick={() => window.scrollTo(0, 0)}
          />
        </div>
      </div>
    </footer>
  )
}

const FooterLogo = ({ className = '' }: { className?: string }) => {
  const isRay = useIsRay()
  return (
    <Block className={`${className}`}>
      {isRay ? (
        <RayLogoFooter className="mb-2" />
      ) : (
        <RvdbLogoFooter className="mb-2" />
      )}
      <CopyRights />
    </Block>
  )
}

const ContactDetails = ({ className = '' }) => (
  <Block className={`${className}`}>
    <Address className={`footer__address `} />
    <Social className="footer__social" />
  </Block>
)
