import { useRouter } from 'next/router'

import Block from '@/layouts/block'
import Main from '@/layouts/main'
import Section from '@/layouts/section'
import { ButtonPrimaryTextAndArrow } from '@/components/button'
import { H404, Q1 } from '@/components/typography'
import { routes } from '@/lib/constants'
import useIsRay from '@/hooks/useIsRay'

interface Props {
  message: string
  resetError: () => void
}

const ErrorFallback = ({ message, resetError }: Props) => {
  const { replace } = useRouter()
  const isRay = useIsRay()
  const homePath = isRay ? routes.ray.dashboard : routes.rvdb.home

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await replace({ pathname: homePath })
    resetError()
  }

  return (
    <Main withGradient>
      <Section className="pt-20 pb-40 px-10 lg:py-10 flex bg-white rounded-[20px] flex-col lg:flex-row justify-start lg:justify-center !max-w-[1360px]">
        <Block className="lg:flex-1 order-2 lg:order-1 flex flex-col justify-center items-center lg:items-start lg:my-28">
          <Q1 variant="rvdb" className="mb-10 text-center lg:text-left">
            Oeps!
            <br /> {message}.
          </Q1>
          <ButtonPrimaryTextAndArrow onClick={handleClick}>
            Ga naar de homepagina
          </ButtonPrimaryTextAndArrow>
        </Block>
        <Block className="lg:flex-1 order-1 lg:order-2 mb-10 lg:mb-0 grid place-items-center">
          <H404>Error</H404>
        </Block>
      </Section>
    </Main>
  )
}

export default ErrorFallback
