import dateFormat from 'dateformat'

import { ButtonLinkSecondaryArrow } from '@/components/button'
import { P1 } from '@/components/typography'

const DateWithBtn = ({
  className = '',
  buttonText = '',
  date,
  buttonLink,
}: {
  className?: string
  buttonText?: string
  date: Date | null
  buttonLink: string
}) => {
  return (
    <div className={`${className} flex justify-between items-center`}>
      {date && <P1>{dateFormat(date, 'dd-mm-yyyy')}</P1>}
      <div className="flex justify-between items-center ml-auto">
        {buttonText && <P1 className="mr-4">{buttonText}</P1>}
        <ButtonLinkSecondaryArrow href={buttonLink} />
      </div>
    </div>
  )
}

export default DateWithBtn
