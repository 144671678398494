import Script from 'next/script'

export default function OnrecruitAnalyticsScript() {
  return (
    <Script id="onrecruit-analytics" strategy="afterInteractive">
      {`
          !function(o,r,e,v,n,t,s){if(o.orq)return;n=o.orq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!o._orq)o._orq=n;
          n.push=n;n.loaded=!0;n.version='3.0';n.queue=[];t=r.createElement(e);t.async=!0;
          t.src=v;s=r.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','//analytics.onrecruit.net/static/scripts/v3/analytics.js?v=' + Math.random());
          orq('init', '242', 'RVDB');
          orq('track', 'conversion');
        `}
    </Script>
  )
}
