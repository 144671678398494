import DateWithBtn from '@/components/date-with-btn'
import { Divider } from '@/components/divider'
import { P1 } from '@/components/typography'
import { useSmallScreen } from '@/hooks/useMediaQuery'
import { StoryCardProps } from '@/interfaces/stories'
import Block from '@/layouts/block'

export const StoryCard = ({
  className = '',
  title,
  publicationDate,
  buttonLink,
  description,
}: StoryCardProps) => {
  const date = new Date(publicationDate)
  const isSmallScreen = useSmallScreen()

  return (
    <Block className={`storyCard ${className}`}>
      <h3 className="H2">{title}</h3>
      {description && !isSmallScreen && <P1>{description}</P1>}
      <div>
        <Divider />
        <DateWithBtn date={date} buttonLink={buttonLink ?? ''} />
      </div>
    </Block>
  )
}
