import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import Image from '@/components/image'
import { P1 } from '@/components/typography'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'

export interface CTAStandardRayCardProps {
  image: Omit<Asset, 'sys' | 'contentfulMetadata' | '_id'> | undefined
  title?: string
  description?: string
  buttonText?: string
  buttonLink: string
  className?: string
}

const CTAStandardRayCard = ({
  image,
  title = '',
  description = '',
  buttonText = '',
  className = '',
  buttonLink,
}: CTAStandardRayCardProps) => {
  return (
    <Block className={`ctaStandardRayCard ${className}`}>
      <Image
        src={image?.url ?? ''}
        className="ctaStandardRayCard__img"
        alt="profile-card"
        height={611}
        width={180}
        unoptimized
      />
      <div className="ctaStandardRayCard__text">
        <h3 className="H2">{title}</h3>
        <P1>{description}</P1>
        <ButtonLinkPrimaryTextAndArrow href={buttonLink}>
          {buttonText}
        </ButtonLinkPrimaryTextAndArrow>
      </div>
    </Block>
  )
}

export default CTAStandardRayCard
