import { RayNavProps } from '@/interfaces/nav'
import NavLargeScreen from './large'
import NavSmallScreen from './small'

export default function Nav({
  isMediumScreen,
  isAuthenticated,
  activeMenuItem,
  isNavOpen,
  toggleNav,
  toggleNavItem,
  isNavItemOpen,
  toggleNavAndNavItems,
}: RayNavProps) {
  return isMediumScreen ? (
    <NavSmallScreen
      activeMenuItem={activeMenuItem}
      isAuthenticated={isAuthenticated}
      isNavOpen={isNavOpen}
      toggleNav={toggleNav}
      toggleNavItem={toggleNavItem}
      isNavItemOpen={isNavItemOpen}
      toggleNavAndNavItems={toggleNavAndNavItems}
    />
  ) : (
    <NavLargeScreen
      activeMenuItem={activeMenuItem}
      isAuthenticated={isAuthenticated}
      toggleNavItem={toggleNavItem}
      isNavItemOpen={isNavItemOpen}
      toggleNavAndNavItems={toggleNavAndNavItems}
    />
  )
}
