import { useSmallScreen } from '@/hooks/useMediaQuery'

interface ArrowIconProps {
  className?: string
  height?: string
  width?: string
}

export const AngleDownIcon = ({ className = '' }: { className?: string }) => {
  return (
    <span className={`icon ${className}`}>
      <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.40234 9.67969C7.71875 9.99609 8.24609 9.99609 8.5625 9.67969L15.418 2.85938C15.7344 2.50781 15.7344 1.98047 15.418 1.66406L14.6094 0.855469C14.293 0.539062 13.7656 0.539062 13.4141 0.855469L8 6.26953L2.55078 0.855469C2.19922 0.539062 1.67188 0.539062 1.35547 0.855469L0.546875 1.66406C0.230469 1.98047 0.230469 2.50781 0.546875 2.85938L7.40234 9.67969Z" />
      </svg>
    </span>
  )
}

export const AngleUpIcon = ({ className = '' }: { className?: string }) => (
  <AngleDownIcon className={`icon rotate-180 ${className}`} />
)

export const ArrowRightIcon = (props: ArrowIconProps) => {
  const isSmallScreen = useSmallScreen()

  return isSmallScreen ? (
    <ArrowRightIconSmall {...props} />
  ) : (
    <ArrowRightIconBig {...props} />
  )
}

export const ArrowRightIconSmall = ({
  className = '',
  width = '18',
  height = '9',
}: ArrowIconProps) => {
  return (
    <span className={`arrow__right-small ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0146484 4H16.5358"
          stroke="#FF128E"
          strokeWidth="1.0875"
          strokeMiterlimit="10"
        />
        <path
          d="M13.2985 9L12.6484 8.19507L16.1027 4.5L12.6484 0.80493L13.2985 0L17.5076 4.5L13.2985 9Z"
          fill="#FF128E"
        />
      </svg>
    </span>
  )
}

export const ArrowRightIconBig = ({
  className = '',
  width = '31',
  height = '15',
}: ArrowIconProps) => {
  return (
    <span className={`arrow__right-big ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 31 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5218_1565)">
          <path d="M0 7.10001H28.59" strokeWidth="1.74" strokeMiterlimit="10" />
          <path d="M22.8402 14.2L21.6602 12.93L27.9302 7.1L21.6602 1.27L22.8402 0L30.4802 7.1L22.8402 14.2Z" />
        </g>
        <defs>
          <clipPath id="clip0_5218_1565">
            <rect width="30.48" height="14.2" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}
