import parse from 'html-react-parser'
import Head from 'next/head'

export interface CodeSnippetProps {
  title: string
  headContent: string
  bodyContent: string
}

export default function CodeSnippet({ code }: { code: CodeSnippetProps }) {
  const { title, headContent, bodyContent } = code
  const id = `code-snippet-${title.replace(/\s+/g, '-').toLowerCase()}`
  return (
    <>
      {headContent ? <Head>{parse(headContent)}</Head> : null}
      {bodyContent ? (
        <div id={id} dangerouslySetInnerHTML={{ __html: bodyContent }} />
      ) : null}
    </>
  )
}
