interface Props {
  className?: string
  src: string
  controls?: boolean
}

export default function InlineVideo({
  className = '',
  src,
  controls = true,
}: Props) {
  return (
    <video
      style={{ maxWidth: '100px', border: '2px solid blue' }}
      className={className + 'test'}
      src={src}
      controls={controls}
    />
  )
}
