import { useId } from 'react'

import { RvdbNavPrimarySmallProps } from '@/interfaces/nav'
import { GRID_ITEM_FULL_WIDTH } from '@/lib/constants'
import RvdbNavItemSmall from './item-small'

export default function RvdbNavPrimarySmall({
  primaryItems,
  toggleNavAndNavItems,
  toggleNavItem,
  openNavItem,
  activeMenuItem,
}: RvdbNavPrimarySmallProps) {
  const id = useId()

  return (
    <div
      className={`${GRID_ITEM_FULL_WIDTH} col-start-1 md:col-end-6 justify-self-start pl-4 md:pl-12 flex flex-col`}
    >
      {primaryItems?.map(({ name, path, icon }, index) => (
        <RvdbNavItemSmall
          key={`${id}-${index}`}
          id={name}
          name={name}
          icon={icon}
          path={path}
          toggleNavItem={toggleNavItem}
          toggleNavAndNavItems={toggleNavAndNavItems}
          openNavItem={openNavItem}
          activeMenuItem={activeMenuItem}
        />
      ))}
    </div>
  )
}
