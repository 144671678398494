export const FilterIcon = ({ width = '20', height = '17', className = '' }) => {
  return (
    <span className={`icon ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.8242 1.32422C18.5781 0.761719 17.9805 0.375 17.3477 0.375H2.61719C1.98438 0.375 1.38672 0.761719 1.14062 1.32422C0.859375 1.88672 0.964844 2.55469 1.35156 3.01172L7.15234 10.1484V12.5391C7.15234 12.9961 7.39844 13.3828 7.75 13.6641L10.6328 15.8789C10.8789 16.0547 11.1602 16.125 11.4414 16.125C12.1797 16.125 12.7773 15.5273 12.7773 14.7891V10.1484L18.6133 3.01172C19 2.55469 19.1055 1.88672 18.8242 1.32422ZM11.3711 9.23438C11.1953 9.44531 11.0898 9.69141 11.0898 9.97266V14.1211L8.83984 12.3633V9.97266C8.83984 9.69141 8.76953 9.44531 8.59375 9.23438L2.75781 2.09766H17.207L11.3711 9.23438Z" />
      </svg>
    </span>
  )
}
