import { SearchResultCard } from '@/components/cards'
import { H2, H3 } from '@/components/typography'
import { SearchResultsProps } from '@/interfaces/search'
import Section from '@/layouts/section'
import { useId } from 'react'

const SearchResults = ({
  searchResults,
  closeSearch,
  toggleNavAndNavItems,
}: SearchResultsProps) => {
  const id = useId()
  return (
    <Section className="searchResults">
      <H2>Zoekresultaten</H2>
      {searchResults.length ? (
        searchResults.map(({ title, type = '', route }, index) => (
          <SearchResultCard
            key={`${id}-${index}`}
            title={title}
            type={type}
            route={`${route}`}
            closeSearch={closeSearch}
            toggleNavAndNavItems={toggleNavAndNavItems}
          />
        ))
      ) : (
        <H3 className="col-start-2 col-end-3 px-[20px]">Geen resultaten</H3>
      )}
    </Section>
  )
}

export default SearchResults
