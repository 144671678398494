export const EuroIcon = ({ className = '', width = '12', height = '17' }) => {
  return (
    <span className={`arrow__right-small ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0742 15.3867L10.8633 14.3672C10.793 14.1211 10.582 13.9805 10.3359 14.0508C9.98438 14.1211 9.42188 14.2266 8.75391 14.2266C6.46875 14.2266 4.64062 12.8203 3.90234 10.8164H8.47266C8.64844 10.8164 8.82422 10.6758 8.85938 10.4648L9.03516 9.62109C9.10547 9.33984 8.89453 9.12891 8.64844 9.12891H3.44531C3.375 8.53125 3.375 7.96875 3.41016 7.44141H9.14062C9.31641 7.44141 9.49219 7.30078 9.52734 7.08984L9.70312 6.24609C9.77344 6 9.5625 5.75391 9.31641 5.75391H3.79688C4.53516 3.67969 6.32812 2.30859 8.71875 2.30859C9.21094 2.30859 9.73828 2.37891 10.0547 2.41406C10.2656 2.44922 10.4766 2.34375 10.5469 2.13281L10.8281 1.07812C10.8633 0.832031 10.7227 0.585938 10.4766 0.550781C10.0898 0.480469 9.45703 0.375 8.75391 0.375C5.23828 0.375 2.42578 2.66016 1.51172 5.71875H0.421875C0.175781 5.71875 0 5.92969 0 6.14062V6.98438C0 7.23047 0.175781 7.40625 0.421875 7.40625H1.23047C1.16016 7.96875 1.19531 8.67188 1.23047 9.09375H0.421875C0.175781 9.09375 0 9.30469 0 9.51562V10.3594C0 10.6055 0.175781 10.7812 0.421875 10.7812H1.54688C2.46094 13.9102 5.16797 16.125 8.75391 16.125C9.59766 16.125 10.3359 15.9844 10.7578 15.8789C11.0039 15.8438 11.1445 15.5977 11.0742 15.3867Z"
          fill="#464646"
        />
      </svg>
    </span>
  )
}
