import { gql } from 'graphql-request'

export const globalSearchQuery = (searchQuery: string) => gql`
  {
    landingPageCollection(
      where: { 
        OR: [{
              bannerTitle_contains: "${searchQuery}" 
            }, { 
              bannerSubtitle_contains: "${searchQuery}"
            }, {
              firstTextSectionTitle_contains: "${searchQuery}"
            }, {
              firstTextSectionContent_contains: "${searchQuery}"
            }, {
              secondTextSectionTitle_contains: "${searchQuery}"
            }, {
              secondTextSectionContent_contains: "${searchQuery}"
            },
          ] }
        limit: 10
    ) {
      items {
        bannerTitle
        slug
        sys {
          publishedAt
        }
        __typename
      }
    }
    consultancyDetailCollection(
      where: {
        OR: [{
              bannerTitle_contains: "${searchQuery}" 
            }, { 
              introductionContent_contains: "${searchQuery}"
            }, {
              contentBody_contains: "${searchQuery}"
            },
            ] }
          limit: 10
    ) {
      items {
        bannerTitle
        slug
        sys {
          publishedAt
        }
        __typename
      }
    }
    eventCollection(where: {
      OR: [{
            title_contains: "${searchQuery}",
          }, {
            description_contains: "${searchQuery}",
          }, {
            content_contains: "${searchQuery}",
          },
        ]
    }
      limit: 10
      ) {
      items {
        title
        slug
        sys {
          publishedAt
        }
        __typename
      }
    }
    legalPageCollection(where: {
        title_contains: "${searchQuery}",
    }
    limit: 10) {
      items {
        title
        slug
        sys {
          publishedAt
        }
        __typename
      }
    }
    storyCollection(where: { displayOnRvdb: true
      OR: [{
            title_contains: "${searchQuery}",
          }, {
            description_contains: "${searchQuery}",
          }, {
            content_contains: "${searchQuery}",
          },
        ]
       }
       limit: 10) {
        items {
          title
          slug
          sys {
          publishedAt
        }
          __typename
      }
    }
    talentProgramDetailCollection(where: {
      OR: [{
            bannerTitle_contains: "${searchQuery}",
          }, {
            introduction_contains: "${searchQuery}",
          },
      ]
    }
    limit: 10) {
      items {
        bannerTitle
        slug
        sys {
          publishedAt
        }
        __typename
      }
    }
  }
`
