import { ErrorMessage } from 'formik'

import { P1 } from '../typography'

interface Props {
  fieldName: string
  className?: string
}

const ValidationError = ({ className = '', fieldName }: Props) => {
  return (
    <ErrorMessage name={fieldName}>
      {(message) => {
        return (
          <P1 className={`${className} bg-error-light p-[20px] rounded-[20px]`}>
            {message}
          </P1>
        )
      }}
    </ErrorMessage>
  )
}

export default ValidationError
