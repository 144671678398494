import { Field, FieldProps } from 'formik'
import {
  useState,
  forwardRef,
  ForwardedRef,
  MouseEvent,
  ReactNode,
} from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import {
  CircleUploadButton,
  DeleteButton,
  ShowPasswordButton,
} from '@/components/button'
import { ArrowRightIconBig, UserNameIcon } from '@/components/icons'
import { H3, P1 } from '@/components/typography'
import {
  InputFieldPasswordProps,
  InputFieldProps,
  InputFieldSearchProps,
  PhoneNumberInputProps,
  TextareaFieldProps,
} from '@/interfaces/input'
import { SearchIcon } from '@/components/icons/search'
import CircularLoader from '@/components/loader/circular'
import ValidationError from '@/components/validation-error'
import { useMediumScreen } from '@/hooks/useMediaQuery'

export default function InputField<T>({
  onChange,
  onBlur,
  name,
  value,
  type = 'text',
  placeholder,
  label,
  wrapperClassName = '',
  fieldClassName = '',
  required = false,
  disabled = false,
}: InputFieldProps<T>) {
  return (
    <div className={`inputField ${wrapperClassName}`}>
      {!!label && <label className={required ? 'required' : ''}>{label}</label>}
      <Field
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        required={required}
        type={type}
        name={name}
        placeholder={placeholder}
        className={`inputField__field ${fieldClassName}`}
        disabled={disabled}
      />
      <ValidationError fieldName={name} />
    </div>
  )
}

export const NewsletterInputField = ({
  name = 'email',
  value,
  onChange,
  wrapperClassName = '',
  fieldClassName = '',
  placeholder = 'E-mailadres',
  onArrowClick,
}: InputFieldProps<string> & {
  onArrowClick: () => void
}) => {
  return (
    <div className="relative w-full">
      <InputField
        type="email"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        fieldClassName={`inputField__with-arrow__btn ${fieldClassName}`}
        wrapperClassName={wrapperClassName}
      />
      <button
        onClick={onArrowClick}
        className={`inputField__with-arrow__arrow inputField__with-arrow__arrow`}
      >
        <ArrowRightIconBig />
      </button>
    </div>
  )
}

export const NameInputField = (props: InputFieldProps<string>) => {
  return (
    <div className="relative w-full">
      <UserNameIcon className="absolute top-[57px] right-[13px]" />
      <InputField {...props} />
    </div>
  )
}

export const UploadInputField = forwardRef(function UploadInputField(
  props: InputFieldProps<string> & {
    openFileInput: (e: MouseEvent<HTMLButtonElement>) => void
    removeFile: () => void
    errorMsg?: string
    isTouched?: boolean
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    wrapperClassName,
    fieldClassName,
    placeholder,
    id,
    name,
    value,
    label,
    onChange,
    openFileInput,
    removeFile,
    required,
    errorMsg,
    isTouched,
  } = props

  return (
    <div className={`uploadInput relative w-full ${wrapperClassName ?? ''}`}>
      {label && (
        <label className={`uploadInput__label ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      {value ? (
        <div className="uploadInput__field">
          <H3>{value}</H3>
          <DeleteButton onClick={removeFile} />
        </div>
      ) : (
        <div className="uploadInput__field">
          <H3>{placeholder}</H3>
          <CircleUploadButton onClick={openFileInput} />
        </div>
      )}
      <input
        type="file"
        id={id}
        name={name}
        placeholder={placeholder}
        accept=".pdf, .doc, .docx, .txt"
        ref={ref}
        hidden
        onChange={onChange}
        className={fieldClassName}
      />
      {isTouched && errorMsg && (
        <P1 className={`$ bg-error-light p-[20px] rounded-[20px]`}>
          {errorMsg}
        </P1>
      )}
    </div>
  )
})

export const TextareaInputField = ({
  name,
  label,
  value,
  placeholder = 'Beschrijf dit punt',
  onChange,
  onBlur,
  rows = 4,
  cols = 50,
  required = false,
  wrapperClassName = '',
  fieldClassName = '',
}: TextareaFieldProps) => {
  return (
    <div className={`textarea ${wrapperClassName}`}>
      {!!label && <label className={required ? 'required' : ''}>{label}</label>}
      <Field
        as="textarea"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        rows={rows}
        cols={cols}
        className={`textarea__field ${fieldClassName}`}
      />
      <ValidationError fieldName={name} />
    </div>
  )
}

export const CheckBoxInputField = (
  props: InputFieldProps<boolean> & {
    children?: ReactNode
  }
) => {
  const { wrapperClassName, fieldClassName, value, children, name, ...rest } =
    props

  return (
    <>
      <div className={`flex items-center ${wrapperClassName ?? ''}`}>
        <Field
          {...rest}
          className={`inputField__checkbox inputField__checkbox ${fieldClassName}`}
          type="checkbox"
          checked={value}
          name={name}
        />
        {children}
      </div>
      <ValidationError fieldName={name} />
    </>
  )
}

export function InputFieldSearch({
  wrapperClassName = '',
  onChange,
  name,
  value,
  type = 'text',
  placeholder,
  autoFocus = false,
  required = false,
  loading,
  customIcon = null,
}: InputFieldSearchProps) {
  const isMediumScreen = useMediumScreen()
  const renderIcon = customIcon ? (
    customIcon
  ) : loading ? (
    <CircularLoader />
  ) : (
    <SearchIcon />
  )

  return (
    <div className={`inputField inputFieldSearch ${wrapperClassName}`}>
      <Field
        autoFocus={autoFocus}
        onChange={onChange}
        value={value}
        required={required}
        type={type}
        name={name}
        placeholder={placeholder}
        className="inputField__field"
      />
      <button className="inputFieldSearch__Btn" type="submit">
        {isMediumScreen ? '' : 'Zoeken'}
        <div className={`inputFieldSearch__Btn__icon`}>{renderIcon}</div>
      </button>
    </div>
  )
}

export function PasswordInputField({
  onChange,
  name,
  value,
  placeholder,
  label,
  wrapperClassName = '',
  fieldClassName = '',
  required = false,
}: InputFieldPasswordProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const toggleShowPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!value) return
    setShowPassword(!showPassword)
  }

  return (
    <div className={`inputField ${wrapperClassName}`}>
      {!!label && <label className={required ? 'required' : ''}>{label}</label>}
      <div className="passwordInputField">
        <Field
          onChange={onChange}
          value={value}
          required={required}
          type={showPassword ? 'text' : 'password'}
          name={name}
          placeholder={placeholder}
          className={`inputField__field ${fieldClassName}`}
        />
        <ShowPasswordButton
          onClick={toggleShowPassword}
          disabled={!value}
          showPassword={showPassword}
        />
      </div>
      <ValidationError fieldName={name} />
    </div>
  )
}

export function PhoneNumberInput({
  placeholder = '',
  label = '',
  value,
  defaultCountry = 'nl',
  onChange,
  required = false,
  wrapperClassName = '',
  fieldClassName = '',
  name,
}: PhoneNumberInputProps) {
  return (
    <div className={`phoneInput ${wrapperClassName}`}>
      {!!label && <label className={required ? 'required' : ''}>{label}</label>}
      <Field name={name}>
        {({ field }: FieldProps) => (
          <PhoneInput
            {...field}
            country={defaultCountry}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            containerClass="phoneInput__container"
            inputClass={`${fieldClassName} phoneInput__field`}
            buttonClass="phoneInput__country"
            inputProps={{
              name,
              required: required,
            }}
          />
        )}
      </Field>
      <ValidationError fieldName={name} />
    </div>
  )
}
