import { Fragment } from 'react'

import { RayLogoWithLink, RvdbButton } from '@/components/button'
import { RayNavLargeScreenProps, RayNavigationItem } from '@/interfaces/nav'
import { routes } from '@/lib/constants'
import { navItems } from './items'
import NavItemLargeWithSubItems from '@/layouts/nav/shared/item-large-with-sub-items'
import NavItemLargeRay from './item-large'
import NavSubItem, { NavSubItemLogout } from '../shared/sub-item'
import { useHeaderHeight } from '@/hooks/useHeaderHeight'
import useIsScrolled from '@/hooks/useIsScrolled'

export default function NavLargeScreen({
  className = '',
  isAuthenticated,
  activeMenuItem,
  isNavItemOpen,
  toggleNavItem,
  toggleNavAndNavItems,
}: RayNavLargeScreenProps) {
  const { headerRef } = useHeaderHeight()
  const isScrolled = useIsScrolled()

  const boxShadow = isScrolled ? 'shadow-[0_0_20px_0_rgba(0,0,0,0.1)]' : ''

  return (
    <header
      ref={headerRef}
      className={`fixed top-0 left-0 w-full grid place-items-center z-[999] bg-white ${boxShadow} ${className}`}
    >
      <div className="grid grid-cols-2 lg:grid-cols-12 gap-lg place-items-center max-w-[1280px] pt-16 pb-7">
        <div className="w-full col-start-1 col-end-3">
          <RayLogoWithLink />
        </div>
        <div className="relative ml-6 flex gap-12 col-start-3 col-end-12">
          {navItems
            .filter((item) => (isAuthenticated ? item : !item.authRequired))
            .map(
              ({
                id = '',
                name = '',
                path = '',
                subItems,
              }: RayNavigationItem) => (
                <Fragment key={id}>
                  {subItems ? (
                    <NavItemLargeWithSubItems
                      id={id}
                      name={name}
                      subItems={subItems}
                      isNavItemOpen={isNavItemOpen}
                      toggleNavItem={toggleNavItem}
                      toggleNavAndNavItems={toggleNavAndNavItems}
                      isActive={
                        !!subItems.find((i) => i.path === activeMenuItem)
                      }
                      render={(subItems) => (
                        <NavSubItemLarge
                          subItems={subItems as RayNavigationItem[]}
                          toggleNavItem={toggleNavItem}
                        />
                      )}
                    />
                  ) : (
                    <NavItemLargeRay
                      id={id}
                      name={name}
                      path={path}
                      isActive={path === activeMenuItem}
                      toggleNavAndNavItems={toggleNavAndNavItems}
                      isNavItemOpen={isNavItemOpen}
                    />
                  )}
                </Fragment>
              )
            )}
        </div>
        <div className="w-full flex justify-end col-start-12">
          <RvdbButton href={routes.rvdb.home} />
        </div>
      </div>
    </header>
  )
}

const NavSubItemLarge = ({
  subItems,
  toggleNavItem,
}: {
  subItems: RayNavigationItem[]
  toggleNavItem: (evt: React.SyntheticEvent) => void
}) => {
  return (
    <div className="navSubItemLarge absolute z-50 top-6 bg-neutral-white p-4 rounded-[20px] w-full max-w-[147px]">
      {(subItems || []).map(({ id = '', path = '', name = '' }) => {
        return name === 'Uitloggen' ? (
          <NavSubItemLogout key={id} onClick={toggleNavItem} />
        ) : (
          <NavSubItem
            key={id}
            id={id}
            path={path}
            name={name}
            onClick={toggleNavItem}
          />
        )
      })}
    </div>
  )
}
