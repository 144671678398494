import { ReactNode } from 'react'

import { ButtonLinkText } from '../button'
import { C1 } from '../typography'

interface ContactInfoProps {
  children: ReactNode
  className?: string
  iconComponent: ReactNode
  href: string
}

const ContactInfo = ({
  children,
  className = '',
  iconComponent,
  href,
}: ContactInfoProps) => {
  return (
    <div className={`${className} contactInfo`}>
      {iconComponent}
      <ButtonLinkText
        href={href ?? ''}
        className="contactInfo__button"
        external
      >
        {children}
      </ButtonLinkText>
    </div>
  )
}

export default ContactInfo
