
export const TwitterIcon = ({ className = '' }: { className?: string }) => {
  return (
    <span className={`${className}`}>
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5556 1.881C18.8283 2.2 18.0534 2.41144 17.2456 2.51411C18.0767 2.01789 18.711 1.23811 19.0092 0.298222C18.2343 0.760222 17.3788 1.08656 16.467 1.26867C15.7312 0.485222 14.6826 0 13.5386 0C11.319 0 9.53211 1.80156 9.53211 4.01011C9.53211 4.32789 9.559 4.63344 9.625 4.92433C6.292 4.76178 3.34278 3.16433 1.36156 0.730889C1.01567 1.331 0.812778 2.01789 0.812778 2.75733C0.812778 4.14578 1.52778 5.37656 2.59356 6.08911C1.94944 6.07689 1.31756 5.88989 0.782222 5.59533C0.782222 5.60756 0.782222 5.62344 0.782222 5.63933C0.782222 7.58756 2.17189 9.20578 3.99422 9.57856C3.66789 9.66778 3.31222 9.71056 2.94311 9.71056C2.68644 9.71056 2.42733 9.69589 2.18411 9.64211C2.70356 11.2298 4.17756 12.397 5.93022 12.4349C4.56622 13.5019 2.83433 14.1448 0.959444 14.1448C0.630667 14.1448 0.315333 14.1301 0 14.0898C1.77589 15.235 3.88056 15.8889 6.15022 15.8889C13.5276 15.8889 17.5609 9.77778 17.5609 4.48067C17.5609 4.30344 17.5548 4.13233 17.5462 3.96244C18.3419 3.39778 19.0104 2.69256 19.5556 1.881Z" fill="#464646" />
      </svg>
    </span>
  )
}

export const FacebookIcon = ({ className = '' }: { className?: string }) => {
  return (
    <span className={`${className}`}>
      <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.4893 2.63607H7.93865V0.111793C7.6886 0.0773953 6.82865 0 5.82714 0C3.73747 0 2.30598 1.3144 2.30598 3.73019V5.95349H0V8.77544H2.30598V15.876H5.13323V8.7761H7.34594L7.6972 5.95415H5.13257V4.01C5.13323 3.19438 5.35285 2.63607 6.4893 2.63607Z" fill="#464646" />
      </svg>
    </span>
  )
}

export const YoutubeIcon = ({ className = '' }: { className?: string }) => {
  return (
    <span className={`${className}`}>
      <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9937 4.60464C20.7538 3.72701 20.047 3.03581 19.1495 2.80124C17.5228 2.375 11 2.375 11 2.375C11 2.375 4.47711 2.375 2.85037 2.80124C1.95291 3.03584 1.24609 3.72701 1.00619 4.60464C0.570312 6.19541 0.570312 9.5144 0.570312 9.5144C0.570312 9.5144 0.570312 12.8334 1.00619 14.4242C1.24609 15.3018 1.95291 15.9642 2.85037 16.1988C4.47711 16.625 11 16.625 11 16.625C11 16.625 17.5228 16.625 19.1495 16.1988C20.047 15.9642 20.7538 15.3018 20.9937 14.4242C21.4296 12.8334 21.4296 9.5144 21.4296 9.5144C21.4296 9.5144 21.4296 6.19541 20.9937 4.60464ZM8.8666 12.5278V6.50101L14.3184 9.51447L8.8666 12.5278Z" fill="#464646" />
      </svg>
    </span>
  )
}

export const LinkedInIcon = ({ className = '' }: { className?: string }) => {
  return (
    <span className={`${className}`}>
      <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.80527 16.6252H0.280804V5.52583H3.80527V16.6252ZM2.04114 4.01176C0.914129 4.01176 0 3.09887 0 1.99672C8.06665e-09 1.46732 0.215048 0.959602 0.597836 0.585258C0.980623 0.210915 1.49979 0.000610352 2.04114 0.000610352C2.58248 0.000610352 3.10165 0.210915 3.48444 0.585258C3.86723 0.959602 4.08228 1.46732 4.08228 1.99672C4.08228 3.09887 3.16777 4.01176 2.04114 4.01176ZM16.9962 16.6252H13.4793V11.2221C13.4793 9.93442 13.4528 8.28305 11.6469 8.28305C9.81446 8.28305 9.53366 9.68207 9.53366 11.1293V16.6252H6.01299V5.52583H9.39326V7.03989H9.44259C9.91313 6.16782 11.0625 5.2475 12.7773 5.2475C16.3443 5.2475 17 7.54458 17 10.5282V16.6252H16.9962Z" fill="#464646" />
      </svg>
    </span>
  )
}

export const InstaIcon = ({ className = '' }: { className?: string }) => {
  return (
    <span className={`${className}`}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 0H11C13.761 0 16 2.239 16 5V11C16 13.761 13.761 16 11 16H5C2.239 16 0 13.761 0 11V5C0 2.239 2.239 0 5 0ZM11 14.5C12.93 14.5 14.5 12.93 14.5 11V5C14.5 3.07 12.93 1.5 11 1.5H5C3.07 1.5 1.5 3.07 1.5 5V11C1.5 12.93 3.07 14.5 5 14.5H11Z" fill="#464646" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12C5.791 12 4 10.209 4 8ZM5.5 8C5.5 9.378 6.622 10.5 8 10.5C9.378 10.5 10.5 9.378 10.5 8C10.5 6.621 9.378 5.5 8 5.5C6.622 5.5 5.5 6.621 5.5 8Z" fill="#464646" />
        <circle cx="12.2996" cy="3.69999" r="0.533" fill="#464646" />
      </svg>
    </span>
  )
}
