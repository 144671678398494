import React from 'react'
import { useRouter } from 'next/router'

import { useAuth } from '@/hooks/auth'
import { routes } from '@/lib/constants'
import PageProps from '@/interfaces/page'
import ProgressBar from '@/components/progress-bar'

export default function withAuth<T>(Page: React.FC<PageProps<T>>) {
  return function Auth(props: PageProps<T>) {
    const { replace, route } = useRouter()
    const { isAuthenticated, user } = useAuth()

    const loginPagePath = routes.rvdb.auth.login
    const rayDashboardPath = routes.ray.dashboard
    const authPathsAsArray = Object.values(routes.rvdb.auth)
    const publicAuthPaths = authPathsAsArray.filter(
      (r) => r !== routes.rvdb.auth.action
    )
    const isPublicAuthPath = publicAuthPaths.includes(route)

    if (!isAuthenticated && !isPublicAuthPath) {
      replace(loginPagePath)

      return <ProgressBar />
    }

    if (isAuthenticated && isPublicAuthPath) {
      replace(rayDashboardPath)

      return <ProgressBar />
    }

    return <Page {...props} user={user} />
  }
}
