import { useHeaderHeight } from '@/hooks/useHeaderHeight'

export default function Main({
  children,
  className = '',
  withGradient = true,
}: {
  children: React.ReactNode
  className?: string
  withGradient?: boolean
}) {
  const { headerRef } = useHeaderHeight()
  const headerHeight = headerRef.current?.clientHeight ?? 0

  return (
    <main
      style={{ marginTop: headerHeight }}
      className={`main ${withGradient ? 'bg-gradient' : ''} ${className}`}
    >
      {children}
    </main>
  )
}
