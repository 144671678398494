// Throughout the app, a number of <h#> components are replaced with vanilla HTML <h#> tags, while using a className that handles their stylinh
// This is because of SEO demands from the client, while the initial design was not set up for this.
// The aim is that a page has one <h1> in the banner; section headers are <h2> and subsections / cards are <h3>
// The styling of these elements is determined by their className, allowing them to be styled as needed

interface TypographyProps {
  children: React.ReactNode | string
  className?: string
  id?: string
  testId?: string
}

interface Q1Props extends TypographyProps {
  variant?: 'rvdb' | 'ray'
}

export const H404 = ({
  children,
  className = '',
  id = '',
}: TypographyProps) => (
  <h1 id={id} className={`H404 ${className}`}>
    {children}
  </h1>
)

export const H1s = ({ children, className = '', id = '' }: TypographyProps) => (
  <h1 id={id} className={`H1s ${className}`}>
    {children}
  </h1>
)

export const H1 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h1 id={id} className={`H1 ${className}`}>
    {children}
  </h1>
)

export const H2 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h2 id={id} className={`H2 ${className}`}>
    {children}
  </h2>
)

export const H3 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h3 id={id} className={`H3 ${className}`}>
    {children}
  </h3>
)

export const H4 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h4 id={id} className={`H4 ${className}`}>
    {children}
  </h4>
)

export const H5 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h5 id={id} className={`H5 ${className}`}>
    {children}
  </h5>
)
export const H6 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h6 id={id} className={`H6 ${className}`}>
    {children}
  </h6>
)

export const H7 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h6 id={id} className={`H7 ${className}`}>
    {children}
  </h6>
)

export const Q1 = ({
  children,
  className = '',
  id = '',
  variant = 'rvdb',
}: Q1Props) => (
  <h1 id={id} className={`Q1 Q1--${variant} ${className}`}>
    {children}
  </h1>
)

export const Q2 = ({ children, className = '', id = '' }: TypographyProps) => (
  <h1 id={id} className={`Q2 ${className}`}>
    {children}
  </h1>
)

export const P1 = ({
  children,
  className = '',
  id = '',
  testId,
}: TypographyProps) => (
  <p id={id} className={`P1  ${className}`} data-test={testId}>
    {children}
  </p>
)

export const P2 = ({ children, className = '', id = '' }: TypographyProps) => (
  <p id={id} className={`P2  ${className}`}>
    {children}
  </p>
)

export const C1 = ({ children, className = '', id = '' }: TypographyProps) => (
  <p id={id} className={`C1 ${className}`}>
    {children}
  </p>
)

export const Bold = ({
  children,
  className = '',
  id = '',
}: TypographyProps) => (
  <b id={id} className={`bold ${className}`}>
    {children}
  </b>
)

export const Italic = ({
  children,
  className = '',
  id = '',
}: TypographyProps) => (
  <i id={id} className={`${className}`}>
    {children}
  </i>
)

export const Li = ({ children, className = '' }: TypographyProps) => (
  <li className={`Li ${className}`}>{children}</li>
)

export const Ul = ({ children, className = '', id = '' }: TypographyProps) => (
  <ul id={id} className={`Ul ${className}`}>
    {children}
  </ul>
)

export const Ol = ({ children, className = '', id = '' }: TypographyProps) => (
  <ol id={id} className={`Ol ${className}`}>
    {children}
  </ol>
)
