import { ChangeEvent, useState } from 'react'
import { AuthError } from 'firebase/auth'

import { sendResetPassEmailFormInitValues } from '@/lib/auth'
import { RecoverPasswordFormValuesTypes } from '@/interfaces/firebase'
import { triggerEmailResetPass } from '@/services/mailgun/api'
import { useRouter } from 'next/router'
import { routes } from '@/lib/constants'

export const useRecoverPasswordForm = () => {
  const { push } = useRouter()
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState(false)
  const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] = useState(false)
  const [formValues, setFormValues] = useState<RecoverPasswordFormValuesTypes>(
    sendResetPassEmailFormInitValues
  )

  const handleCloseErrorSnackbar = () => setErrorSnackbarOpen(false)
  const handleCloseSuccessSnackbar = () => setSuccessSnackbarOpen(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setFormValues({ email: value })
  }

  const handleSubmitRecoverPassForm = async () => {
    const { email } = formValues
    setLoading(true)

    try {
      await triggerEmailResetPass(email)
      setSuccessMessage(
        'Check! Er is een e-mail naar je inbox verzonden. Zie je hem?'
      )
      setSuccessSnackbarOpen(true)
    } catch (err: any) {
      if (err.message === 'Verificatie vereist, je wordt doorgeleid.') {
        setTimeout(() => {
          push(routes.rvdb.auth.validate)
        }, 3000)
      }
      setErrorMessage(err.message)
      setErrorSnackbarOpen(true)
    } finally {
      setLoading(false)
    }
  }

  return {
    formValues,
    successMessage,
    errorMessage,
    isLoading,
    isErrorSnackbarOpen,
    isSuccessSnackbarOpen,
    handleCloseErrorSnackbar,
    handleCloseSuccessSnackbar,
    handleSubmitRecoverPassForm,
    handleInputChange,
  }
}
