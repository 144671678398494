import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import useSWR from 'swr'

import Block from '@/layouts/block'
import { globalSearchQuery } from '@/services/contentful/queries/globalSearch'
import { fetchData } from '@/services/contentful/api/getData'
import { InputFieldSearch } from '@/components/input'
import GenericFormElement from '@/components/form'
import SearchResults from '@/sections/rvdb/search-results'
import Snackbar from '@/components/snackbar'
import { routes } from '@/lib/constants'
import {
  GlobalSearchProps,
  GlobalSearchQueryRes,
  MappedSearchResults,
  SearchBarProps,
  CompleteSearchResults,
  SearchRoutes,
} from '@/interfaces/search'
import { CloseButton, SearchButton } from '../button'

const searchRoutesObj: {
  [key: string]: SearchRoutes
} = {
  ConsultancyDetail: {
    route: routes.rvdb.rvdbConsultancy,
    type: 'Consultancy',
  },
  Event: {
    route: routes.rvdb.events,
    type: 'Event',
  },
  LandingPage: {
    route: '',
    type: '',
  },
  Story: {
    route: routes.rvdb.knowledgeAndInspiration,
    type: 'Story',
  },
  TalentProgramDetail: {
    route: routes.rvdb.talentProgram.talentProgram,
    type: 'Talent Program',
  },
  LegalPage: {
    route: routes.rvdb.legal.legal,
    type: '',
  },
}

const overviewPageObj: { [key: string]: CompleteSearchResults } = {
  werken_bij: {
    route: routes.rvdb.werkenBij,
    title: 'Werken bij',
  },
  contact: {
    route: routes.rvdb.contact,
    title: 'Contact',
  },
  ons_team: {
    route: routes.rvdb.onsTeam,
    title: 'Ons team',
  },
  kennis_en_inspiratie: {
    route: routes.rvdb.knowledgeAndInspiration,
    title: 'Kennis en inspiratie',
  },
  talent_program: {
    route: routes.rvdb.talentProgram.talentProgram,
    title: 'Talent program',
  },
  events: {
    route: routes.rvdb.events,
    title: 'Events',
  },
  vacatures: {
    route: routes.rvdb.vacancies,
    title: 'Vacatures',
  },
  consultancy: {
    route: routes.rvdb.rvdbConsultancy,
    title: 'Consultancy',
  },
}

function GlobalSearch({
  renderCustomIcon,
  closeSearch,
  toggleNavAndNavItems,
}: GlobalSearchProps) {
  const [searchResults, setSearchResults] = useState<
    CompleteSearchResults[] | null
  >(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [doFetch, setDoFetch] = useState<boolean>(false)
  // doFetch state is used to trigger SWR fetch - we use this, because the fetch happens onClick

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false)
  const handleCloseErrorSnackbar = () => setErrorSnackbarOpen(false)

  const mapSearchResults = useCallback(
    (searchResponse: GlobalSearchQueryRes) => {
      const mapTypename = (
        typename: string = '',
        slug: string = ''
      ): SearchRoutes => ({
        route: `${searchRoutesObj[typename].route}/${slug}`,
        type: searchRoutesObj[typename].type,
      })

      const {
        consultancyDetailCollection,
        eventCollection,
        landingPageCollection,
        legalPageCollection,
        storyCollection,
        talentProgramDetailCollection,
      } = searchResponse

      const mappedResults: MappedSearchResults[] = []

      consultancyDetailCollection?.items.forEach(
        ({ __typename, bannerTitle, slug, sys }) =>
          mappedResults.push({
            ...mapTypename(__typename, slug),
            title: bannerTitle ?? '',
            publishedAt: sys.publishedAt,
          })
      )

      eventCollection?.items.forEach(({ __typename, title, slug, sys }) =>
        mappedResults.push({
          ...mapTypename(__typename, slug),
          title: title ?? '',
          publishedAt: sys.publishedAt,
        })
      )

      landingPageCollection?.items.forEach(
        ({ __typename, bannerTitle, slug, sys }) =>
          mappedResults.push({
            ...mapTypename(__typename, slug),
            title: bannerTitle ?? '',
            publishedAt: sys.publishedAt,
          })
      )

      legalPageCollection?.items.forEach(({ __typename, title, slug, sys }) =>
        mappedResults.push({
          ...mapTypename(__typename, slug),
          title: title ?? '',
          publishedAt: sys.publishedAt,
        })
      )

      storyCollection?.items.forEach(({ __typename, title, slug, sys }) =>
        mappedResults.push({
          ...mapTypename(__typename, slug),
          title: title ?? '',
          publishedAt: sys.publishedAt,
        })
      )

      talentProgramDetailCollection?.items.forEach(
        ({ __typename, bannerTitle, slug, sys }) =>
          mappedResults.push({
            ...mapTypename(__typename, slug),
            title: bannerTitle ?? '',
            publishedAt: sys.publishedAt,
          })
      )

      return mappedResults
    },
    []
  )

  const orderSearchResults = useCallback(
    (searchResponse: GlobalSearchQueryRes): CompleteSearchResults[] => {
      const mappedResults = mapSearchResults(searchResponse)
      return mappedResults
        .sort(
          (a, b) =>
            new Date(b.publishedAt).valueOf() -
            new Date(a.publishedAt).valueOf()
        ) // first sort on most recent
        .sort((a, b) => (a.type === '' ? -1 : 1)) // then prioritize landing pages
        .map(({ publishedAt, ...rest }) => rest) // remove publishedAt from the object to match type CompleteSearchResults
    },
    [mapSearchResults]
  )

  const getOverviewPageResults = (): CompleteSearchResults[] => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase()

    return Object.keys(overviewPageObj)
      .filter((key) => {
        const cleanKey = key.replace('_', ' ') // replace underscore in order to match a fully written search query
        return cleanKey.includes(lowerCaseSearchQuery)
      })
      .map((key) => overviewPageObj[key])
  }

  const handleInputSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setDoFetch(false)
    setSearchQuery(value)
  }

  const handleSubmitSearch = () => {
    setDoFetch(true)
  }

  const { data, error } = useSWR<GlobalSearchQueryRes, Error>(
    !doFetch ? null : globalSearchQuery(searchQuery),
    fetchData
  )

  useEffect(() => {
    if (!data || !doFetch) return

    const overviewPageResults = getOverviewPageResults()
    const orderedSearchResults = orderSearchResults(data)
    const results = [...overviewPageResults, ...orderedSearchResults].slice(
      0,
      10
    )

    setSearchResults(results)
  }, [data, doFetch, orderSearchResults, getOverviewPageResults])

  if (error) {
    setErrorMessage(
      error?.message ??
        'Ai! Er gaat wat fout bij het ophalen van zoekresultaten...'
    )
    setErrorSnackbarOpen(true)
  }

  return (
    <>
      <GenericFormElement
        wrapperClassName="globalSearch__formEl"
        submitForm={handleSubmitSearch}
        initialValues={{ searchQuery: '' }}
      >
        <InputFieldSearch
          autoFocus
          name="global-search"
          value={searchQuery}
          loading={false}
          placeholder="Je zoekopdracht..."
          onChange={handleInputSearchQueryChange}
          customIcon={renderCustomIcon}
        />
      </GenericFormElement>
      {searchResults && (
        <SearchResults
          toggleNavAndNavItems={toggleNavAndNavItems}
          searchResults={searchResults}
          closeSearch={closeSearch}
        />
      )}
      <Snackbar
        message={errorMessage}
        isOpen={isErrorSnackbarOpen}
        onClose={handleCloseErrorSnackbar}
      />
    </>
  )
}

const SearchBar = ({
  className = '',
  toggleNavAndNavItems,
}: SearchBarProps) => {
  const ref = useRef(null)
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false)
  const toggleSeachOpen = () => setSearchOpen(!isSearchOpen)
  const closeSearch = () => setSearchOpen(false)
  useOnClickOutside(ref, closeSearch)

  const renderCustomIcon = isSearchOpen ? (
    <CloseButton className="block" onClick={toggleSeachOpen} />
  ) : null

  return (
    <Block className={`${className} globalSearch`} ref={ref}>
      {isSearchOpen ? (
        <GlobalSearch
          toggleNavAndNavItems={toggleNavAndNavItems}
          renderCustomIcon={renderCustomIcon}
          closeSearch={closeSearch}
        />
      ) : (
        <SearchButton
          className="globalSearch__icon"
          onClick={toggleSeachOpen}
        />
      )}
    </Block>
  )
}

export default SearchBar
