import { AuthError } from 'firebase/auth'
import { ChangeEvent, useState } from 'react'

import { getErrorMsg } from '@/lib/auth/auth-validation'
import { useAuth } from './useAuthContext'

interface ChangePasswordForm {
  newPassword: string
  confirmNewPassword: string
}

export const useChangePasswordForm = () => {
  const { changePassword } = useAuth()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false)
  const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] =
    useState<boolean>(false)
  const [formValues, setFormValues] = useState<ChangePasswordForm>({
    newPassword: '',
    confirmNewPassword: '',
  })

  const handleCloseErrorSnackbar = () => setErrorSnackbarOpen(false)
  const handleCloseSuccessSnackbar = () => setSuccessSnackbarOpen(false)

  const handleSubmitChangePassword = async () => {
    const { newPassword } = formValues

    try {
      await changePassword(newPassword)
      setSuccessMessage('Check! Je wachtwoord is gewijzigd!')
      setSuccessSnackbarOpen(true)
    } catch (err: unknown) {
      setErrorMessage(getErrorMsg((err as AuthError).code))
      setErrorSnackbarOpen(true)
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setFormValues((prev: ChangePasswordForm) => ({
      ...prev,
      [name]: value,
    }))
  }

  return {
    errorMessage,
    successMessage,
    isErrorSnackbarOpen,
    isSuccessSnackbarOpen,
    handleCloseErrorSnackbar,
    handleCloseSuccessSnackbar,
    formValues,
    handleInputChange,
    handleSubmitChangePassword,
  }
}
