import React from 'react'

export const PlayIcon = ({ width = '94', height = '94' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        opacity="0.5"
        cx="46.9677"
        cy="46.97"
        rx="46.9677"
        ry="46.97"
        fill="#464646"
      />
      <path
        d="M64.8035 45.8558C65.9255 46.6531 65.9255 48.3191 64.8035 49.1164L40.0626 66.6977C38.7384 67.6387 36.9041 66.6919 36.9041 65.0674L36.9041 29.9048C36.9041 28.2802 38.7384 27.3335 40.0626 28.2745L64.8035 45.8558Z"
        fill="#0BE8AB"
      />
    </svg>
  )
}
