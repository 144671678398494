import { NextApiRequest } from 'next'
import { DecodedIdToken } from 'firebase-admin/auth'

import { Candidate, Person } from './candidate'
import { MatchCriteriaProps } from './match-criteria'
import { FileType } from '@/interfaces/files'
import { EmailActionMode } from './email'
import {
  ActivateEellooUserDataPayload,
  AddEellooUserDataPayload,
  GetEellooBrowserTokenDataPayload,
} from './eelloo'
import { VacanciesSelectionId } from './vacancy'

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ResponseStatus {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NOT_ALLOWED = 405,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
}

export interface HttpSuccessResponseObj<T> {
  result: {
    data: T
  }
  success: boolean
}

export interface HttpErrorResponseObj {
  result: {
    error: Error
  }
  success: boolean
}

export type HttpResponseObj<T> = HttpSuccessResponseObj<T> &
  HttpErrorResponseObj

export interface GenericRequestType extends NextApiRequest {
  headers: {
    authorization: string
  }
}

export interface AddCandidateApiRequest extends GenericRequestType {
  body: Person
  sid: string
}

export interface UpdateCandidateApiRequest extends GenericRequestType {
  body: {
    person: Candidate['person']
    matchCriteria: MatchCriteriaProps
    extraInfo2?: string
    extraInfo6?: string
  }
  query: {
    cid: string
  }
  sid: string
}

export interface GetCandidateByIdApiRequest extends GenericRequestType {
  sid: string
  decodedToken: DecodedIdToken
}

export interface checkIfCandidateExistsApiRequest {
  query: {
    email: string
  }
  sid: string
}

export interface ListMatchCriteriaApiRequest extends GenericRequestType {
  sid: string
}

export interface CreateApplicationApiRequest extends NextApiRequest {
  body: {
    vacancyId: string
    vacancyName: string
    applicationData: string
    fileType: FileType
  }
  files: {
    cvDoc: Buffer | null
    otherDocs: Buffer | null
  }
  sid: string
}

export interface CreateOpenApplicationApiRequest extends NextApiRequest {
  body: {
    vacancyId: string
    vacancyName: string
    applicationData: string
    fileType: FileType
  }
  files: {
    cvDoc: Buffer | null
    otherDocs: Buffer | null
  }
  sid: string
  decodedToken: DecodedIdToken
}

export interface GetVacancyDetailApiRequest extends NextApiRequest {
  query: {
    vid: string
  }
  sid: string
}

export interface ListVacanciesApiRequest extends GenericRequestType {
  sid: string
}

export interface VacanciesSelectionApiRequest extends GenericRequestType {
  sid: string
  body: VacanciesSelectionId
}

export interface GetDocumentApiRequest extends GenericRequestType {
  body: {
    fileType: FileType
  }
  files: {
    cvDoc: Buffer | null
    otherDocs: Buffer | null
  }
  sid: string
  decodedToken: DecodedIdToken
}

export interface ListDocumentTypesApiRequest extends GenericRequestType {
  sid: string
}

export interface DispatchEmailApiRequest extends GenericRequestType {
  query: {
    email: string
    action: EmailActionMode
    userName: string
    vacancyName?: string
  }
}

export interface AuthenticateEellooApiRequest extends NextApiRequest {
  method: RequestMethod
}

export interface AddEellooUserApiReq extends GenericRequestType {
  body: AddEellooUserDataPayload
  authToken: string
}

export interface ActivateEellooUserApiReq extends GenericRequestType {
  body: ActivateEellooUserDataPayload
  authToken: string
}

export interface GetEellooBrowserTokenApiReq extends GenericRequestType {
  body: GetEellooBrowserTokenDataPayload
  authToken: string
}

export interface CreateAuthUserApiRequest extends GenericRequestType {
  body: {
    email: string
    password: string
    uid: string
  }
  authToken: string
}

export interface UpdateProfileApiRequest extends NextApiRequest {
  body: {
    person: Person
    matchCriteria?: Partial<MatchCriteriaProps>
    extraInfo2?: string
    extraInfo6?: string
    updatedFields: {
      person?: Person
      matchCriteria?: Partial<MatchCriteriaProps>
      extraInfo2?: string
      extraInfo6?: string
      file?: FileType[]
    }
  }
  sid: string
  decodedToken: DecodedIdToken
}

export interface CreateUserAccountApiRequest extends GenericRequestType {
  body: {
    userData: string
    fileType: FileType
  }
  files: {
    cvDoc: Buffer | null
    otherDocs: Buffer | null
  }
  sid: string
  authToken: string
}

export interface CreateUserAccountApiResponse extends GenericRequestType {
  email: string
  firstName: string
  lastName: string
  infix: string
  phoneNumber: string
  candidateId: string
}

export interface ApplyToOpenVacancyApiResponse extends GenericRequestType {
  email: string
  firstName: string
  lastName: string
  infix: string
  phoneNumber: string
  candidateId: string
}

export interface UpdateAvailabilityApiRequest extends NextApiRequest {
  body: {
    available_by: string
  }
  sid: string
  decodedToken: DecodedIdToken
}

export interface LoginApiRequest extends NextApiRequest {
  body: {
    email: string
    password: string
  }
  sid: string
  authToken: string
}

export interface UploadPictureApiRequest extends NextApiRequest {
  sid: string
  body: FormData
  decodedToken: DecodedIdToken
}

export interface ListFilesApiRequest extends NextApiRequest {
  sid: string
  decodedToken: DecodedIdToken
}

export interface DeleteFileApiRequest extends NextApiRequest {
  sid: string
  query: {
    fid: string
  }
}
