import { useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { AngleDownIcon, AngleUpIcon } from '@/components/icons'
import { Li } from '@/components/typography'
import Block from '@/layouts/block'
import { NavItemLargeWithSubItemsProps } from '@/interfaces/nav'

export default function NavItemLargeWithSubItems<T>({
  id = '',
  name = '',
  subItems,
  isActive,
  isNavItemOpen,
  toggleNavItem,
  toggleNavAndNavItems,
  render,
}: NavItemLargeWithSubItemsProps) {
  const ref = useRef(null)
  const closeNav = () => isNavItemOpen && toggleNavAndNavItems()
  useOnClickOutside(ref, closeNav)

  return (
    <Block className="flex flex-col" ref={ref} testId='navItemLargeWithSubItems'>
      <button
        className="flex items-center hover:cursor-pointer"
        onClick={toggleNavItem}
        id={id}
      >
        <Li
          className={`navItemLargeWithSubItems pr-2 ${
            isActive ? `navItemLargeWithSubItems--active` : ''
          }`}
        >
          {name}
        </Li>
        {isNavItemOpen ? <AngleUpIcon /> : <AngleDownIcon />}
      </button>
      {isNavItemOpen && render && render(subItems ?? [], toggleNavAndNavItems)}
    </Block>
  )
}
