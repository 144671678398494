import { forwardRef } from 'react'

import MuiSnackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Slide, { SlideProps } from '@mui/material/Slide'

import MuiPalette from '@/components/mui-theme'

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface Props {
  variant?: SnackbarVariants
  isOpen: boolean
  onClose: (event?: React.SyntheticEvent | Event) => void
  message: string
  autoHideDuration?: number
}

export enum SnackbarVariants {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export default function Snackbar({
  message,
  variant = SnackbarVariants.ERROR,
  autoHideDuration = 6000,
  isOpen,
  onClose,
}: Props) {
  return (
    <MuiPalette>
      <MuiSnackbar
        open={isOpen}
        onClose={onClose}
        autoHideDuration={autoHideDuration}
        TransitionComponent={SlideTransition}
      >
        <Alert onClose={onClose} severity={variant} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </MuiSnackbar>
    </MuiPalette>
  )
}
