import { ReactNode, MouseEvent, ButtonHTMLAttributes } from 'react'

interface RenderProps {
  children: string | ReactNode
}

interface ButtonBaseProps<T> {
  children?: string | ReactNode
  render?: (p: RenderProps) => ReactNode
  className?: string
  id?: string
  variant?: ButtonVariants
  version?: 'a' | 'b' | 'c'
  props?: T
  isDisabled?: boolean
}

export interface ButtonType<T> extends ButtonBaseProps<T> {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  formId?: string | undefined
}

export interface LinkType<T> extends ButtonBaseProps<T> {
  href?: string
  as?: string
  external?: boolean
  onClick?: ((event: MouseEvent<any>) => void) | undefined
}

export enum ButtonVariants {
  PLAIN = 'plain',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUARTIARY = 'quartiary',
  TEXT = 'text',
  ANGLE = 'angle',
  ICON = 'icon',
  LOAD_MORE = 'loadMore',
  RAY = 'ray',
  RVDB = 'rvdb',
}
