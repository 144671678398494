import React, { ReactElement } from 'react'
import ReactPlayer from 'react-player'

import { PlayIcon } from '@/components/icons'

type Props = {
  className?: string
  url: string
  width?: string | number
  height?: string | number
  controls: boolean
  muted: boolean
  playIcon?: ReactElement
}

export default function ReactVideo({
  className = '',
  url,
  width,
  height,
  controls,
  muted,
  playIcon = <PlayIcon />,
}: Props) {
  return (
    <ReactPlayer
      className={className}
      width={width}
      height={height}
      url={url}
      controls={controls}
      muted={muted}
      playIcon={playIcon}
    />
  )
}
