import { ReactNode } from 'react'

import {
  TwitterIcon,
  FacebookIcon,
  YoutubeIcon,
  LinkedInIcon,
  InstaIcon,
} from '@/components/icons'
import Link from '@/components/link'
import Block from '@/layouts/block'

const SocialIcon = ({
  href,
  iconComponent,
}: {
  href: string
  iconComponent: ReactNode
}) => (
  <Link className="mr-auto" href={href} external>
    {iconComponent}
  </Link>
)

const Social = ({ className = '' }: { className?: string }) => {
  return (
    <Block className={`${className}`}>
      <SocialIcon
        href="https://www.linkedin.com/company/rvdb-hr/"
        iconComponent={<LinkedInIcon />}
      />
      <SocialIcon
        href="https://www.facebook.com/people/Rvdb-hr-adviesbureau/100063646748235/"
        iconComponent={<FacebookIcon />}
      />
      <SocialIcon
        href="https://twitter.com/rvdbhr"
        iconComponent={<TwitterIcon />}
      />
      <SocialIcon
        href="https://www.youtube.com/channel/UCZDT6mg4LIAjXsYSa24kI4g"
        iconComponent={<YoutubeIcon />}
      />
      <SocialIcon
        href="https://www.instagram.com/rvdb_hr/"
        iconComponent={<InstaIcon />}
      />
    </Block>
  )
}

export default Social
