import { RvdbNavProps } from '@/interfaces/nav'
import RvdbNavLarge from './large'
import RvdbNavSmall from './small'

export default function RvdbNav({
  isMediumScreen,
  isSmallScreen,
  activeMenuItem,
  isNavOpen,
  toggleNav,
  toggleNavItem,
  openNavItem,
  toggleNavAndNavItems,
  primaryItems,
  secondaryItems,
}: RvdbNavProps) {
  return isSmallScreen || isMediumScreen ? (
    <RvdbNavSmall
      activeMenuItem={activeMenuItem}
      isNavOpen={isNavOpen}
      toggleNav={toggleNav}
      toggleNavItem={toggleNavItem}
      openNavItem={openNavItem}
      toggleNavAndNavItems={toggleNavAndNavItems}
      primaryItems={primaryItems}
      secondaryItems={secondaryItems}
    />
  ) : (
    <RvdbNavLarge
      activeMenuItem={activeMenuItem}
      toggleNavItem={toggleNavItem}
      toggleNavAndNavItems={toggleNavAndNavItems}
      openNavItem={openNavItem}
      primaryItems={primaryItems}
      secondaryItems={secondaryItems}
    />
  )
}
