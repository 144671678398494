import { Fragment } from 'react'

import NavigationDropdownLarge from '@/components/dropdown/navigation/large'
import { Li } from '@/components/typography'
import {
  RvdbNavItemLargeWithoutSubProps,
  RvdbNavItemLargeProps,
  RvdbNavSubItemWithSubLargeProps,
} from '@/interfaces/nav'
import Section from '@/layouts/section'
import NavItemLargeWithSubItems from '../shared/item-large-with-sub-items'
import Link from '@/components/link'
import { NavigationSubitem } from '@/interfaces/schema'

export function RvdbNavItemLargeWithoutSub({
  path = '',
  isActive,
  name,
  toggleNavAndNavItems,
}: RvdbNavItemLargeWithoutSubProps) {
  return (
    <div className="pb-10 lg:pb-0 flex items-center">
      <Link href={path} onClick={toggleNavAndNavItems}>
        <Li
          className={`rvdbNavItemLargeWithoutSub ${
            isActive ? 'rvdbNavItemLargeWithoutSub--active' : ''
          }}`}
        >
          {name}
        </Li>
      </Link>
    </div>
  )
}

export const RvdbNavSubItemWithSubLarge = ({
  subItems,
  toggleNavAndNavItems,
  navCards,
  rightBlockContent,
  rightBlockButtonText,
  rightBlockButtonPath,
  displayRayButton,
  displaySocials,
}: RvdbNavSubItemWithSubLargeProps) => {
  return (
    <Section className="w-full mx-auto right-0 left-0 !max-w-[1440px] absolute z-50 top-[152px]">
      <NavigationDropdownLarge
        toggleNavAndNavItems={toggleNavAndNavItems}
        subItems={subItems}
        navCards={navCards}
        rightBlockContent={rightBlockContent}
        rightBlockButtonText={rightBlockButtonText}
        rightBlockButtonPath={rightBlockButtonPath}
        displayRayButton={displayRayButton}
        displaySocials={displaySocials}
        activeMenuItem={''}
      />
    </Section>
  )
}

const RvdbNavItemLarge = ({
  id,
  path,
  name,
  subItems,
  navCards,
  rightBlockContent,
  rightBlockButtonText,
  rightBlockButtonPath,
  displayRayButton,
  displaySocials,
  openNavItem,
  toggleNavItem,
  toggleNavAndNavItems,
  activeMenuItem,
}: RvdbNavItemLargeProps) => {
  return (
    <Fragment>
      {!path || !!subItems?.length ? (
        <NavItemLargeWithSubItems
          id={id}
          path={path ?? ''}
          name={name}
          subItems={subItems}
          isNavItemOpen={openNavItem === id}
          toggleNavItem={toggleNavItem}
          toggleNavAndNavItems={toggleNavAndNavItems}
          isActive={!!subItems?.find((i: any) => i.path === activeMenuItem)}
          render={(subItems) => (
            <RvdbNavSubItemWithSubLarge
              subItems={subItems as NavigationSubitem[]}
              toggleNavAndNavItems={toggleNavAndNavItems}
              navCards={navCards}
              rightBlockContent={rightBlockContent}
              rightBlockButtonText={rightBlockButtonText}
              rightBlockButtonPath={rightBlockButtonPath}
              displayRayButton={displayRayButton}
              displaySocials={displaySocials}
            />
          )}
        />
      ) : (
        <RvdbNavItemLargeWithoutSub
          name={name}
          path={path ?? ''}
          isActive={path === activeMenuItem}
          toggleNavAndNavItems={toggleNavAndNavItems}
        />
      )}
    </Fragment>
  )
}

export default RvdbNavItemLarge
