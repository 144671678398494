import { ChangeEvent, useState } from 'react'
import { useRouter } from 'next/router'
import { AxiosError } from 'axios'

import { LoginFormValuesTypes } from '@/interfaces/firebase'
import { loginFormInitValues } from '@/lib/auth'
import { useAuth } from '@/hooks/auth'
import { routes } from '@/lib/constants'

export const useLogin = () => {
  const { push } = useRouter()
  const { login } = useAuth()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [formValues, setFormValues] =
    useState<LoginFormValuesTypes>(loginFormInitValues)

  const handleCloseSnackbar = () => setSnackbarOpen(false)

  const handleLoginInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setFormValues((prev: LoginFormValuesTypes) => ({
      ...prev,
      [name]: value,
    }))
  }

  function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).name === 'AxiosError'
  }

  async function handleSubmitLoginForm() {
    const { email, password } = formValues

    if (login == null) {
      throw new Error('login is null')
    }

    setLoading(true)

    try {
      const response = await login(email, password)

      if (isAxiosError(response)) {
        if (response?.response?.status === 404) {
          setErrorMessage(
            'Ai! We konden dit account helaas niet vinden, probeer graag opnieuw.'
          )
          setSnackbarOpen(true)
          setLoading(false)
          return
        }

        if (
          response?.response?.status === 400 &&
          (response?.response?.data as any)?.result?.error?.message ===
            'Verificatie vereist, je wordt doorgeleid.'
        ) {
          setErrorMessage('Verificatie vereist, je wordt doorgeleid.')
          setSnackbarOpen(true)
          setTimeout(() => {
            push(routes.rvdb.auth.validate)
          }, 2000)
          return
        }

        console.error('unhandled auth error')
        console.error(response)
        setErrorMessage(
          (response?.response?.data as any)?.result?.error?.message ?? ''
        )
        setSnackbarOpen(true)
        setLoading(false)
        return
      }

      await push(routes.rvdb.auth.emailLoginSent)
    } catch (err) {
      const error = err as Error
      const errorMsg: string =
        error?.message ?? 'Oops! Inloggen mislukt. Probeer graag opnieuw.'
      setLoading(false)
      setErrorMessage(errorMsg)
      setSnackbarOpen(true)
    }
  }

  return {
    formValues,
    errorMessage,
    isLoading,
    isSnackbarOpen,
    handleCloseSnackbar,
    handleLoginInputChange,
    handleSubmitLoginForm,
  }
}
