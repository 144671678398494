import { useState } from 'react'
import { useRouter } from 'next/router'

import RvdbNav from '@/layouts/nav/rvdb'
import { useLargeScreen, useSmallScreen } from '@/hooks/useMediaQuery'
import { RvdbNavigation } from '@/interfaces/schema'

interface Props {
  data: RvdbNavigation | null
}

export default function Header({ data }: Props) {
  const { pathname } = useRouter()
  const mediumScreen = useLargeScreen()
  const smallScreen = useSmallScreen()
  const isMediumScreen = mediumScreen && !smallScreen
  const isSmallScreen = mediumScreen && smallScreen

  const [isNavOpen, setNavOpen] = useState(false)
  const [openNavItem, setOpenNavItem] = useState('')

  const toggleNav = () => setNavOpen(!isNavOpen)

  const openSelectedNavItem = (evt: React.SyntheticEvent) => {
    const id = evt.currentTarget.id
    if (!id) return

    if (openNavItem === id) return setOpenNavItem('')
    return setOpenNavItem(id)
  }

  const toggleNavAndNavItems = () => {
    setNavOpen(!isNavOpen)
    setOpenNavItem('')
  }

  if (!data) return <></>

  const {
    primaryNavigationItemsCollection,
    secondaryNavigationItemsCollection,
  } = data

  return (
    <RvdbNav
      activeMenuItem={pathname}
      isNavOpen={isNavOpen}
      toggleNav={toggleNav}
      openNavItem={openNavItem}
      toggleNavItem={openSelectedNavItem}
      toggleNavAndNavItems={toggleNavAndNavItems}
      isMediumScreen={isMediumScreen}
      isSmallScreen={isSmallScreen}
      primaryItems={primaryNavigationItemsCollection?.items}
      secondaryItems={secondaryNavigationItemsCollection?.items}
    />
  )
}
