import { ReactNode } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF128E'
    },
    secondary: {
      main: '#0BE8AB'
    },
    success: {
      main: '#0BE8AB'
    },
    error: {
      main: '#FF128E'
    }
  }
});

export default function MuiPalette({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}
