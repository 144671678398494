import { useHover } from 'usehooks-ts'
import { CSSProperties, useRef } from 'react'

import { Divider } from '@/components/divider'
import Image from '@/components/image'
import { P2, C1 } from '@/components/typography'
import { Employee } from '@/interfaces/schema'
import Block from '@/layouts/block'
import {
  ContactEmailIcon,
  ContactLinkedInIcon,
  ContactPhoneIcon,
} from '@/components/icons'
import ContactInfo from '@/components/contact-info'

type PersonCardProps = Omit<Employee, 'contentfulMetadata' | 'sys' | '_id'> & {
  className?: string
  order?: number
}

const PersonCard = ({
  className = '',
  professionalImage,
  playfulImage,
  name,
  position,
  order,
  phoneNumber = '',
  emailAddress = '',
  linkedinLink = '',
}: PersonCardProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const isHover = useHover(ref)
  const image = isHover ? playfulImage : professionalImage

  return (
    <Block
      className={`personCard ${className}`}
      style={{ '--order': order } as CSSProperties}
      ref={ref}
    >
      <Image
        className="personCard__img"
        src={image?.url ?? ''}
        alt="person-card"
        width={545}
        height={165}
      />
      <div className="personCard__personalia">
        <P2 className="mb-[10px]">{name}</P2>
        <C1>{position}</C1>
      </div>
      <Divider className="justify-self-end" />
      <div className="personCard__contactDetails">
        {phoneNumber && (
          <ContactInfo
            className="pb-[5px]"
            iconComponent={<ContactPhoneIcon />}
            href={`tel:${phoneNumber}`}
          >
            <C1>{phoneNumber}</C1>
          </ContactInfo>
        )}
        {emailAddress && (
          <ContactInfo
            className="pb-[5px]"
            iconComponent={<ContactEmailIcon />}
            href={`mailto:${emailAddress}`}
          >
            <C1>{emailAddress}</C1>
          </ContactInfo>
        )}
        {linkedinLink && (
          <ContactInfo
            className="pb-[5px]"
            iconComponent={<ContactLinkedInIcon />}
            href={linkedinLink}
          >
            <C1>LinkedIn</C1>
          </ContactInfo>
        )}
      </div>
    </Block>
  )
}

export default PersonCard
