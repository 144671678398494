import { ChangeEvent, useState } from 'react'
import { AuthError } from 'firebase/auth'
import { useRouter } from 'next/router'

import { useAuth } from '@/hooks/auth'
import { getErrorMsg, resetPasswordFormInitValues } from '@/lib/auth'
import { ChangePasswordForm } from '@/interfaces/firebase'
import { routes } from '@/lib/constants'

export const useResetPassword = (oobCode: string) => {
  const { resetPassword } = useAuth()
  const { replace } = useRouter()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [formValues, setFormValues] = useState<ChangePasswordForm>(
    resetPasswordFormInitValues
  )

  const handleCloseSnackbar = () => setSnackbarOpen(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setFormValues((prev: ChangePasswordForm) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmitResetPassForm = async () => {
    const { newPassword } = formValues
    setLoading(true)

    try {
      await resetPassword?.({
        oobCode,
        newPassword,
      })

      await replace(routes.ray.dashboard)
    } catch (err: unknown) {
      const errorMessage = getErrorMsg((err as AuthError).code)
      setSnackbarOpen(true)
      setErrorMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  return {
    formValues,
    errorMessage,
    isLoading,
    isSnackbarOpen,
    handleCloseSnackbar,
    handleSubmitResetPassForm,
    handleInputChange,
  }
}
