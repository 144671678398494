const InnerHtml = ({
  className = '',
  innerHtml,
}: {
  className?: string
  innerHtml: string
}) => {
  const formattedHtml = innerHtml
    .replace(/<h1>/g, '<h1 class="H1">')
    .replace(/<H1>/g, '<h1 class="H1">')
    .replace(/<h2>/g, '<p class="H2">')
    .replace(/<H2>/g, '<p class="H2">')
    .replace(/<h3>/g, '<p class="H3">')
    .replace(/<H3>/g, '<p class="H3">')
    .replace(/<P>/g, '<p class="P1 mb-6">')
    .replace(/<p>/g, '<p class="P1 mb-6">')
    .replace(/<div>/g, '<p class="P1">')
    .replace(/<LI>/g, '<li class="Li Li--withStyle">')
    .replace(/<li>/g, '<li class="Li Li--withStyle">')
    .replace(/<UL>/g, '<ul class="Ul">')
    .replace(/<ul>/g, '<ul class="Ul">')
    .replace(/<OL>/g, '<ol class="Ol">')
    .replace(/<ol>/g, '<ol class="Ol">')

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: `<p class="P1">${formattedHtml}</p>` }}
    />
  )
}

export default InnerHtml
