import { FileType } from '@/interfaces/files'
import { createInitValuesObject } from '../object'
import { SignupFormValuesTypes } from '@/interfaces/firebase'

export const loginFormInitValues = createInitValuesObject({
  email: '',
  password: '',
  verificationCode: '',
})

export const signUpFormInitValues: SignupFormValuesTypes =
  createInitValuesObject({
    firstName: '',
    infix: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    extraInfo7: '',
    verificationCode: '',
    privacyAgreement: false,
    files: {
      cvDoc: {
        type: FileType.CV,
        name: '',
        data: null,
      },
      otherDocs: {
        type: FileType.WORK_PERMIT,
        name: '',
        data: null,
      },
    },
  })

export const changePasswordFormInitValues = createInitValuesObject({
  newPassword: '',
  confirmNewPassword: '',
})

export const resetPasswordFormInitValues = createInitValuesObject({
  newPassword: '',
  confirmNewPassword: '',
})

export const sendResetPassEmailFormInitValues = createInitValuesObject({
  email: '',
})

export const setupPasswordFormInitValues = createInitValuesObject({
  password: '',
  confirmPassword: '',
})
