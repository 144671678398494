export const GRID = 'grid grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-lg'
export const GRID_ITEM_FULL_WIDTH =
  'col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-13'
export const CARD =
  'py-[30px] px-[15px] md:p-[40px] bg-neutral-white rounded-[20px]'

// !TO DO clean this up; update when new Rvdb colors are confirmed in design
export enum Colors {
  PINK = '#FF128E',
  ORANGE = '#FF6612',
  GREEN = '#0BE8AB',
  WHITE = '#ffffff',
  BLUE_LIGHT = '#EBF5F7',
  BLUE = '#3488A9',
  GREY = '#AEB0B4',
  BLACK = '#464646',
}

export enum MuiColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum Directions {
  PREV = 'prev',
  NEXT = 'next',
}

export const routes = {
  ray: {
    dashboard: '/ray',
    faq: '/ray/#faq',
    bedankt: '/ray/bedankt',
    my_profile: {
      profile: '/ray/profiel',
      account: '/ray/account',
    },
    vacancies: '/ray/vacatures',
    applyToVacancy: (id: string) => `/ray/vacatures/${id}/sollicitatie`,
    knowledgeAndInspiration: '/ray/kennis-en-inspiratie',
    events: '/ray/events',
  },
  rvdb: {
    home: '/',
    faq: '/#faq',
    contact: '/contact',
    werkenBij: '/werken-bij',
    bedankt: '/bedankt',
    // landing page ↓
    about: '/over-ons',
    onsTeam: '/ons-team',
    // landing page ↓
    knowledgeAndInspiration: '/kennis-en-inspiratie',
    // landing page ↓
    successStories: '/succesverhalen',
    rvdbConsultancy: '/consultancy',
    // landing page ↓
    recruitment: '/recruitment',
    // landing page ↓
    interimProfessionals: '/interim-professionals',
    vacancies: '/vacatures',
    // landing page ↓
    talentscan: '/talentscan',
    // landing page ↓
    watIsRay: '/wat-is-ray',
    events: '/events',
    talentProgram: {
      talentProgram: '/talent-program',
      talentProgramQR: '/talent-program/qr-talent-program',
      talentProgramHR: '/talent-program/hr-talent-program',
    },
    legal: {
      legal: '/legal',
      privacyPolicy: '/legal/privacy-policy',
      algemeneVoorwaarden: '/legal/algemene-voorwaarden',
      disclaimer: '/legal/disclaimer',
    },
    auth: {
      validate: '/auth/validate',
      login: '/auth/login',
      emailLogin: '/auth/email-login',
      emailLoginSent: '/auth/email-login-sent',
      signup: '/auth/signup',
      verifyEmail: '/auth/verify-email',
      recoverPassword: '/auth/recover-password',
      setupPassword: '/auth/setup-password',
      action: '/auth/action',
    },
  },
}

export const footerIds = {
  rvdb: '6R8Z2XXTDxJ4ooLs08f4Uh',
  ray: '1tNs9QHGfWJBBBdXfADG8M',
}

export const matchCriteriaFilterIds = ['17', '11', '8', '12', '1'] //  the order of these IDs determines the order in which the match criteria are fetched and displayed in the UI
