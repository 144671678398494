interface Environment {
  prod: string
  stag: string
  dev: string
  test?: string
}

enum EnvironmentValue {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  TEST = 'test',
}

export default function getValueByEnv({
  prod,
  stag,
  dev,
  test = '',
}: Environment) {
  const environment = process.env.NEXT_PUBLIC_APP_ENV

  switch (environment) {
    case EnvironmentValue.PRODUCTION:
      return prod
    case EnvironmentValue.STAGING:
      return stag
    case EnvironmentValue.TEST:
      return test
    default:
      return dev
  }
}

export const openVacancyStatusId = getValueByEnv({
  dev: '15348',
  prod: '4969',
  stag: '4969',
})

// Otys: Nieuw
export const newPublishedStatusId = getValueByEnv({
  dev: '5941',
  stag: '2304',
  prod: '2304',
})

// Otys: Gewijzigd profiel
export const modifiedProfileStatusId = getValueByEnv({
  dev: '5943',
  stag: '2692',
  prod: '2692',
})

// Otys: On Hold
export const onHoldPublishedStatusId = getValueByEnv({
  dev: '5945',
  stag: '2349',
  prod: '2349',
})

// Open inschrijving; Otys: Open sollicitatie
export const openApplicationStatusId = getValueByEnv({
  dev: '5944',
  stag: '3298',
  prod: '3298',
})

// Otys: Vergevorderd
export const advancedPublishedStatusId = getValueByEnv({
  dev: '8119',
  stag: '2351',
  prod: '2351',
})

// Otys: Spoed
export const urgentStatusId = getValueByEnv({
  dev: '5942',
  stag: '2347',
  prod: '2347',
})

export const defaultOwnerId = getValueByEnv({
  dev: '57098',
  prod: '62235',
  stag: '62235',
})

export const procedureStatusUid = getValueByEnv({
  dev: '1_20902',
  stag: '1_7822',
  prod: '1_7822',
})

export const killerQuestionId = getValueByEnv({
  dev: '1293281',
  stag: '1204239',
  prod: '1204239',
})

export const mcRecruitmentId = getValueByEnv({
  dev: '17_8869',
  stag: '17_2672',
  prod: '17_2672',
})

export const mcZzpId = getValueByEnv({
  dev: '17_8870',
  stag: '17_2673',
  prod: '17_2673',
})

export const mcRvdbId = getValueByEnv({
  dev: '17_10432',
  stag: '17_4160',
  prod: '17_4160',
})

export const personStatusId = getValueByEnv({
  dev: '-1',
  test: '-1',
  prod: '-1',
  stag: '-1',
})
