import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  applyActionCode,
  AuthError,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signOut,
  updatePassword,
  User,
} from 'firebase/auth'
import { useLocalStorage } from 'usehooks-ts'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'

import { API_ENDPOINTS } from '@/services/serverless/api/config'
import { authClient } from '@/services/firebase/client/config'
import { AuthProps, AuthUser } from '@/interfaces/firebase'
import LocalStorageData from '@/interfaces/local-storage'
import initLocalStorageData from '@/lib/factory/local-storage'
import { getErrorMsg } from '@/lib/auth/auth-validation'
import { routes } from '@/lib/constants'

const AuthContext = createContext<AuthProps>({
  user: null,
  isAuthenticated: false,
  setIsAuthenticated: null,
  verifyEmail: null,
  resetPassword: null,
  recoverPassword: null,
  updateUser: null,
  login: null,
  loginWithCustomToken: null,
  signup: null,
  migrate: null,
  logout: () => {
    return false
  },
  changePassword: () => {
    return false
  },
})

export const useAuth = () => useContext(AuthContext)

// TODO handle firebase error mapping here only and not in any callable instance
export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const auth = getAuth()
  const { replace } = useRouter()
  const [user, setUser] = useState<AuthUser | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [, setLocalStorageData] = useLocalStorage<LocalStorageData>(
    'rvdb',
    initLocalStorageData
  )

  const updateUser = useCallback((user: AuthUser) => {
    setUser(user)
  }, [])

  // Verify email address upon signup action.
  const verifyEmail = async (actionCode: string) => {
    try {
      return await applyActionCode(auth, actionCode)
    } catch (error) {
      throw new Error(getErrorMsg((error as AuthError).code))
    }
  }

  const signup = async (email: string, password: string): Promise<User> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        authClient,
        email,
        password
      )

      return userCredential.user
    } catch (err: unknown) {
      const error = err as Error | AuthError
      throw new Error(getErrorMsg((error as AuthError).code))
    }
  }

  async function migrate(email: string, password: string) {
    await axios.post(API_ENDPOINTS.AUTH.MIGRATE, { email, password })
  }

  const login = async (
    email: string,
    password: string,
    _?: string
  ): Promise<AxiosResponse | AxiosError> => {
    // eslint-disable-next-line no-console
    let result
    try {
      return await axios.post(API_ENDPOINTS.AUTH.LOGIN, { email, password })
    } catch (e: any) {
      return e
    }
  }

  const loginWithCustomToken = async (token: string) => {
    try {
      await signInWithCustomToken(auth, token)
      return true
    } catch (err: unknown) {
      console.error(err)
      throw err
    }
  }

  const logout = async (): Promise<void | Error> => {
    try {
      setUser(null)
      setIsAuthenticated(false)
      setLocalStorageData(initLocalStorageData)

      await signOut(authClient)
    } catch (err: unknown) {
      const error = err as Error | AuthError
      throw new Error(getErrorMsg((error as AuthError).code))
    }
  }

  const recoverPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email)
  }

  const resetPassword = ({
    newPassword,
    oobCode,
  }: {
    newPassword: string
    oobCode: string
  }) => {
    return confirmPasswordReset(auth, oobCode, newPassword)
  }

  const changePassword = async (newPassword: string) => {
    if (!auth.currentUser) return
    await updatePassword(auth.currentUser, newPassword)
    await reauthenticateWithCredential(
      auth.currentUser,
      EmailAuthProvider.credential(auth.currentUser.email ?? '', newPassword)
    )
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authClient, (user) => {
      if (user) {
        // This is a work around, because Firebase does NOT include
        // the reloadUserInfo property in the User interface.
        const reloadUserInfo =
          (user as unknown as AuthUser)?.reloadUserInfo ?? null

        updateUser({
          ...user,
          reloadUserInfo,
        })

        if (user?.emailVerified) setIsAuthenticated(true)
        else {
          setIsAuthenticated(false)
          replace(routes.rvdb.auth.verifyEmail + '?email=' + user.email)
        }

        // !Temporarily commented out
        // if (reloadUserInfo?.mfaInfo) setIsAuthenticated(true)
      } else {
        setUser(null)
        setIsAuthenticated(false)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [updateUser])

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(authClient, (user) => {
      if (user) {
        user
          .getIdToken()
          .then((idToken) => {
            setLocalStorageData((prev: LocalStorageData) => {
              return {
                ...prev,
                idToken,
              }
            })
          })
          .catch((error) => {
            throw error
          })
      }
    })

    return () => unsubscribe()
  }, [setLocalStorageData])

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        setIsAuthenticated,
        login,
        loginWithCustomToken,
        signup,
        logout,
        migrate,
        verifyEmail,
        recoverPassword,
        resetPassword,
        updateUser,
        changePassword,
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  )
}
