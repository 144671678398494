import React, {
  createContext,
  useRef,
  useContext,
  ReactNode,
  RefObject,
  useState,
  useEffect,
} from 'react'

interface HeaderHeightContextValue {
  headerRef: RefObject<HTMLElement>
  headerHeight: number
}

const HeaderHeightContext = createContext<HeaderHeightContextValue>({
  headerRef: { current: null },
  headerHeight: 0,
})

export const HeaderHeightProvider = ({
  children,
  isPageWithoutLayouts,
}: {
  children: ReactNode
  isPageWithoutLayouts: boolean
}) => {
  const headerRef = useRef<HTMLElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    if (isPageWithoutLayouts) setHeaderHeight(0)
    else if (headerRef.current) setHeaderHeight(headerRef.current.clientHeight)
  }, [headerRef.current, isPageWithoutLayouts])

  return (
    <HeaderHeightContext.Provider value={{ headerRef, headerHeight }}>
      {children}
    </HeaderHeightContext.Provider>
  )
}

export const useHeaderHeight = () => useContext(HeaderHeightContext)
