import React from 'react'

export enum DividerColors {
  WHITE = 'white',
  BLACK = 'black',
}

export const Divider = ({
  className = '',
  color,
}: {
  className?: string
  color?: DividerColors
}) => {
  return (
    <div
      className={`divider ${color ? `divider--${color}` : ''} ${className}`}
    />
  )
}
