import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import { H2, P1, P2, Q1, Q2 } from '@/components/typography'
import Block from '@/layouts/block'

interface CTAStandardRvdbCardProps {
  className?: string
  title: string
  buttonText: string
  buttonLink: string
  description: string
}

export const CTAStandardRvdbGradientCard = ({
  className = '',
  title,
  buttonText,
  buttonLink,
  description,
}: CTAStandardRvdbCardProps) => {
  return (
    <Block className={`ctaStandardRvdbGradientCard ${className}`}>
      <h3 className="H2">{title}</h3>
      <P1>{description}</P1>
      <ButtonLinkPrimaryTextAndArrow href={buttonLink} version="b">
        <P2>{buttonText}</P2>
      </ButtonLinkPrimaryTextAndArrow>
    </Block>
  )
}

export const CTAStandardRvdbWhiteBgCard = ({
  className = '',
  title,
  description,
  buttonText,
  buttonLink,
}: CTAStandardRvdbCardProps) => {
  return (
    <Block className={`ctaStandardRvdbWhiteBgCard ${className}`}>
      <h3 className="H2">{title}</h3>
      <P1>{description}</P1>
      <ButtonLinkPrimaryTextAndArrow href={buttonLink} external>
        <P2 className="!text-neutral-white">{buttonText}</P2>
      </ButtonLinkPrimaryTextAndArrow>
    </Block>
  )
}

export const CTAStandardRvdbTallCardWithBtn = ({
  className = '',
  title,
  description,
  buttonText,
  buttonLink,
}: CTAStandardRvdbCardProps) => {
  return (
    <Block className={`ctaStandardRvdbTallWithBtnCard ${className}`}>
      <h3 className="Q2">{title}</h3>
      <P1>{description}</P1>
      <ButtonLinkPrimaryTextAndArrow href={buttonLink} external>
        <P2 className="!text-neutral-white">{buttonText}</P2>
      </ButtonLinkPrimaryTextAndArrow>
    </Block>
  )
}

export const CTAStandardRvdbTallCard = ({
  className = '',
  title,
}: {
  className?: string
  title: string
}) => {
  return (
    <Block className={`ctaStandardRvdbTallCard ${className}`}>
      <h3 className="Q1">{title}</h3>
    </Block>
  )
}
