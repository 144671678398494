import { OtysErrorResponse, OtysSuccessResponse } from './json-rpc'

export enum FileField {
  CV_DOC = 'cvDoc',
  OTHER_DOCS = 'otherDocs',
}

export enum FileType {
  ACQUISITIE = 'Acquisitie',
  AFSPRAAK = 'Afspraak',
  BIJLAGE = 'Bijlage',
  CONTACT = 'Contact',
  CONTRACT = 'Contract',
  CV = 'CV',
  DRIVERS_LICENCE = 'Drivers Licence',
  EMOLUMENTEN = 'Emolumenten',
  GESPREKSVERSLAG = 'Gespreksverslag',
  GREEN_CARD = 'Green Card',
  HR_BELLIJN = 'HR Bellijn',
  ID_CARD = 'ID Card',
  KANDIDATEN_VOORGESTELD = 'Kandidaten voorgesteld',
  LINKEDIN_EXTENTIE = 'LinkedIn extentie',
  MAILWISSELING = 'Mailwisseling',
  OFFERTE = 'Offerte',
  PASSPORT = 'Passport',
  PRINT_CV = 'Print CV',
  PROJECT_PERFORMANCE_MANAGEMENT = 'Project Performance Management',
  PROJECT_REORGANISEREN_TRANSFORMEREN = 'Project Reorganiseren & Transformeren',
  TARIEF_FACTURATIE_AFSPRAKEN = 'Tarief / facturatie afspraken',
  VAR = 'VAR',
  VAT = 'VAT',
  VCA = 'VCA',
  VERLENGING = 'Verlenging',
  VOORSTELSTUKJE = 'Voorstelstukje',
  WORK_PERMIT = 'Work Permit',
  PICTURE = 'picture',
}

export const fileTypeIds = {
  '216': FileType.ACQUISITIE,
  '210': FileType.AFSPRAAK,
  '208': FileType.BIJLAGE,
  '209': FileType.CONTACT,
  '179': FileType.CONTRACT,
  '1': FileType.CV,
  '174': FileType.DRIVERS_LICENCE,
  '211': FileType.EMOLUMENTEN,
  '201': FileType.GREEN_CARD,
  '215': FileType.HR_BELLIJN,
  '172': FileType.ID_CARD,
  '222': FileType.KANDIDATEN_VOORGESTELD,
  '180': FileType.LINKEDIN_EXTENTIE,
  '220': FileType.MAILWISSELING,
  '224': FileType.OFFERTE,
  '171': FileType.PASSPORT,
  '2': FileType.PRINT_CV,
  '219': FileType.PROJECT_PERFORMANCE_MANAGEMENT,
  '218': FileType.PROJECT_REORGANISEREN_TRANSFORMEREN,
  '223': FileType.TARIEF_FACTURATIE_AFSPRAKEN,
  '177': FileType.VAR,
  '176': FileType.VAT,
  '178': FileType.VCA,
  '221': FileType.VERLENGING,
  '213': FileType.VOORSTELSTUKJE,
  '175': FileType.WORK_PERMIT,
}

export type FileTypeKey = keyof typeof fileTypeIds

export type FileTypes = Record<string | number, FileType>

export enum FileDataClass {
  TK = 'TK',
  ATTACHED_DOCS = 'AttachedDocs',
}

export interface BufferFileProps {
  type: FileType | undefined
  name: string | undefined
  fileTypeId?: string | null
  data: Buffer | null
}

export interface BufferFilesProps {
  cvDoc: BufferFileProps
  otherDocs: BufferFileProps
  picture: BufferFileProps
}

export interface BlobFileProps {
  type: FileType
  name: string
  data: File | null
}

export interface BlobFilesProps {
  cvDoc: BlobFileProps
  otherDocs: BlobFileProps
}

export interface FileTypeAsOption {
  label: FileType
  value: FileType
}

export interface BindFileData {
  subject: string
  typeId: FileTypeKey
  private?: boolean
  alwaysOnTop?: boolean
  candidateUid: string
  fileUid: string
}

export interface FileData {
  id: string
  klantid: number
  id_entity: number
  class: FileDataClass | string
  id_rec: number
  dt: string
  date: string
  subject: string
  from: string
  pvar1: null | string
  pvar2: null | string
  pvar3: null | string
  pvar4: null | string
  pvar5: FileType | null | string
  pvar6: null | string
  pvar7: null | string
  pvar8: null | string
  pvar9: null | string
  pint1: null | boolean | number
  pint2: null | boolean | number
  pint3: null | boolean | number
  pint4: null | boolean | number
  pint5: null | boolean | number
  pint6: null | boolean | number
  pint7: null | boolean | number
  incoming_outgoing: string
  entityLinks: null | string
  alwaysOnTop: number
  stiplyStatus: null | string
  commercialMark: boolean
  commercialMarkEnabled: boolean
  processStep: string
  category: null | string
  downloadUrl?: string
}

export type FileWithDownloadUrl = FileData & { downloadUrl: string }

export interface ProfileFiles {
  cvDoc: FileWithDownloadUrl | null
  otherDocs: FileWithDownloadUrl[]
}

export type FileDataListResponse = OtysSuccessResponse<FileData[]> &
  OtysErrorResponse

export type UploadFileSuccessResData = Record<
  string,
  {
    ouid: string
    name: string
    mimeType: string
    size: number
  }
>

export type BindDocResponse = OtysSuccessResponse<string> & OtysErrorResponse
