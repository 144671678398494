import { ButtonLinkPrimaryTextAndArrow, RayButton } from '@/components/button'
import NavigationCard from '@/components/cards/navigation'
import { Li, P1 } from '@/components/typography'
import Link from '@/components/link'
import Block from '@/layouts/block'
import { RvdbNavigationDropdownLarge } from '@/interfaces/nav'
import RichTextRenderer from '@/services/contentful/utils/richText'
import Social from '@/blocks/social'
import { routes } from '@/lib/constants'

export default function NavigationDropdownLarge({
  activeMenuItem,
  subItems,
  toggleNavAndNavItems,
  navCards,
  rightBlockContent,
  rightBlockButtonText,
  rightBlockButtonPath,
  displayRayButton,
  displaySocials,
}: RvdbNavigationDropdownLarge) {
  return (
    <div className="navigationDropdownLarge">
      <Block className="navigationDropdownLarge__leftBlock">
        {(subItems || []).map(({ name, path }) => (
          <Li key={name} className="navigationDropdownLarge__item">
            <Link href={path ?? ''} onClick={toggleNavAndNavItems}>
              {name}
            </Link>
          </Li>
        ))}
      </Block>
      <Block className="navigationDropdownLarge__middleBlock">
        {navCards?.map(
          ({ title = '', description = '', image, path = '' }, index) => (
            <NavigationCard
              key={index}
              title={title}
              text={description}
              imgSrc={image?.url ?? ''}
              path={path}
              toggleNavAndNavItems={toggleNavAndNavItems}
            />
          )
        )}
      </Block>
      <Block className="navigationDropdownLarge__rightBlock">
        <RichTextRenderer richText={rightBlockContent} />
        {rightBlockButtonPath && rightBlockButtonText && (
          <ButtonLinkPrimaryTextAndArrow
            onClick={toggleNavAndNavItems}
            href={rightBlockButtonPath ?? ''}
          >
            {rightBlockButtonText}
          </ButtonLinkPrimaryTextAndArrow>
        )}
        {displayRayButton && (
          <div className="flex items-center gap-[20px]">
            <P1>Log in op </P1>
            <RayButton
              onClick={toggleNavAndNavItems}
              href={routes.ray.dashboard}
            />
          </div>
        )}
        {displaySocials && (
          <Social className="navigationDropdownLarge__rightBlock__social" />
        )}
      </Block>
    </div>
  )
}
