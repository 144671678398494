import { P1 } from '@/components/typography'
import { Divider } from '@/components/divider'
import Image from '@/components/image'
import Block from '@/layouts/block'
import DateWithBtn from '@/components/date-with-btn'
import { StoryCardWithImageProps } from '@/interfaces/stories'
import Link from '@/components/link'

const StoryCardWithImage = (props: StoryCardWithImageProps) => {
  if (props.imgPosition === 'top') {
    return <StoryCardWithImageTop {...props} />
  }
  if (props.imgPosition === 'right') {
    return <StoryCardWithImageRight {...props} />
  }
  return <StoryCardWithImageLeft {...props} />
}

export default StoryCardWithImage

const StoryCardWithImageText = ({
  publicationDate = null,
  title,
  description,
  buttonLink,
  buttonText,
}: Omit<StoryCardWithImageProps, 'imgPosition' | 'image' | 'className'>) => {
  return (
    <Block className="storyCardWithImg__text">
      <Link href={buttonLink ?? ''}>
        <h3 className="H2">{title}</h3>
      </Link>
      <P1>{description}</P1>
      <div>
        <Divider />
        <DateWithBtn
          date={publicationDate}
          buttonLink={buttonLink ?? ''}
          buttonText={buttonText}
        />
      </div>
    </Block>
  )
}

const StoryCardWithImageTop = ({
  title,
  publicationDate = null,
  buttonLink,
  image,
  description,
  className = '',
  buttonText,
}: Omit<StoryCardWithImageProps, 'imgPosition'>) => {
  return (
    <Block className={`storyCardWithImg ${className}`}>
      <Link href={buttonLink ?? ''}>
        <Image
          className="storyCardWithImg__img"
          src={image?.url ?? ''}
          alt="story-card"
          height="412px"
          width="620px"
        />
      </Link>
      <StoryCardWithImageText
        publicationDate={publicationDate}
        description={description}
        buttonLink={buttonLink}
        title={title}
        buttonText={buttonText}
      />
    </Block>
  )
}

const StoryCardWithImageRight = ({
  className = '',
  image,
  title,
  description,
  buttonLink,
  publicationDate,
  buttonText,
}: StoryCardWithImageProps) => {
  return (
    <Block className={`storyCardWithImg storyCardWithImg--right ${className}`}>
      <Link href={buttonLink ?? ''}>
        <Image
          className="storyCardWithImg--right__img"
          src={image?.url ?? ''}
          alt="profile-card"
          layout="fill"
          width={650}
          height={432}
        />
      </Link>
      <StoryCardWithImageText
        publicationDate={publicationDate}
        description={description}
        buttonLink={buttonLink}
        title={title}
        buttonText={buttonText}
      />
    </Block>
  )
}

const StoryCardWithImageLeft = ({
  className = '',
  image,
  title,
  description,
  buttonLink,
  publicationDate,
  buttonText,
}: StoryCardWithImageProps) => {
  return (
    <Block className={`storyCardWithImg storyCardWithImg--left ${className}`}>
      <Link href={buttonLink ?? ''}>
        <Image
          className="storyCardWithImg--left__img"
          src={image?.url ?? ''}
          alt="profile-card"
          layout="fill"
          width={650}
          height={432}
        />
      </Link>
      <StoryCardWithImageText
        publicationDate={publicationDate}
        description={description}
        buttonLink={buttonLink}
        title={title}
        buttonText={buttonText}
      />
    </Block>
  )
}
