export const UserNameIcon = ({
  width = '21',
  height = '24',
  className = '',
}) => {
  return (
    <span className={`icon ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.6719 14.25C13.3125 14.25 12.7031 15 10.5 15C8.25 15 7.64062 14.25 6.28125 14.25C2.8125 14.25 0 17.1094 0 20.5781V21.75C0 23.0156 0.984375 24 2.25 24H18.75C19.9688 24 21 23.0156 21 21.75V20.5781C21 17.1094 18.1406 14.25 14.6719 14.25ZM18.75 21.75H2.25V20.5781C2.25 18.3281 4.03125 16.5 6.28125 16.5C6.98438 16.5 8.0625 17.25 10.5 17.25C12.8906 17.25 13.9688 16.5 14.6719 16.5C16.9219 16.5 18.75 18.3281 18.75 20.5781V21.75ZM10.5 13.5C14.2031 13.5 17.25 10.5 17.25 6.75C17.25 3.04688 14.2031 0 10.5 0C6.75 0 3.75 3.04688 3.75 6.75C3.75 10.5 6.75 13.5 10.5 13.5ZM10.5 2.25C12.9375 2.25 15 4.3125 15 6.75C15 9.23438 12.9375 11.25 10.5 11.25C8.01562 11.25 6 9.23438 6 6.75C6 4.3125 8.01562 2.25 10.5 2.25Z" />
      </svg>
    </span>
  )
}

export const AddIcon = ({ width = '16', height = '17', className = '' }) => {
  return (
    <span className={`icon ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.75 7.0625H9.6875V2C9.6875 1.40234 9.16016 0.875 8.5625 0.875H7.4375C6.80469 0.875 6.3125 1.40234 6.3125 2V7.0625H1.25C0.617188 7.0625 0.125 7.58984 0.125 8.1875V9.3125C0.125 9.94531 0.617188 10.4375 1.25 10.4375H6.3125V15.5C6.3125 16.1328 6.80469 16.625 7.4375 16.625H8.5625C9.16016 16.625 9.6875 16.1328 9.6875 15.5V10.4375H14.75C15.3477 10.4375 15.875 9.94531 15.875 9.3125V8.1875C15.875 7.58984 15.3477 7.0625 14.75 7.0625Z" />
      </svg>
    </span>
  )
}

export const UploadIcon = ({ className = '', width = '24', height = '19' }) => {
  return (
    <span className={`icon ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.4062 14.25C13.8633 14.25 14.25 13.8984 14.25 13.4062V7.5H17.3086C17.9414 7.5 18.2578 6.76172 17.8008 6.30469L12.457 0.960938C12.2109 0.714844 11.7539 0.714844 11.5078 0.960938L6.16406 6.30469C5.70703 6.76172 6.02344 7.5 6.65625 7.5H9.75V13.4062C9.75 13.8984 10.1016 14.25 10.5938 14.25H13.4062ZM21 13.9688C21 13.5117 20.6133 13.125 20.1562 13.125H15.375V13.4062C15.375 14.4961 14.4609 15.375 13.4062 15.375H10.5938C9.50391 15.375 8.625 14.4961 8.625 13.4062V13.125H3.84375C3.35156 13.125 3 13.5117 3 13.9688V17.9062C3 18.3984 3.35156 18.75 3.84375 18.75H20.1562C20.6133 18.75 21 18.3984 21 17.9062V13.9688ZM16.6406 17.0625C16.6406 17.4492 16.3242 17.7656 15.9375 17.7656C15.5508 17.7656 15.2344 17.4492 15.2344 17.0625C15.2344 16.6758 15.5508 16.3594 15.9375 16.3594C16.3242 16.3594 16.6406 16.6758 16.6406 17.0625ZM18.8906 17.0625C18.8906 17.4492 18.5742 17.7656 18.1875 17.7656C17.8008 17.7656 17.4844 17.4492 17.4844 17.0625C17.4844 16.6758 17.8008 16.3594 18.1875 16.3594C18.5742 16.3594 18.8906 16.6758 18.8906 17.0625Z" />
      </svg>
    </span>
  )
}
