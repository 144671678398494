export const EyeSlashIcon = ({
  width = '24',
  height = '19',
  className = '',
}) => {
  return (
    <span className={`${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9258 12.625L8.41016 9.88281C8.72656 11.4648 10.0977 12.6602 11.7148 12.6602C11.7852 12.6602 11.8555 12.625 11.9258 12.625ZM11.75 14.3477H11.7148C8.9375 14.3477 6.65234 12.0625 6.65234 9.28516C6.65234 9.03906 6.72266 8.79297 6.72266 8.58203L3.34766 5.91016C2.71484 6.75391 2.1875 7.66797 1.73047 8.65234C1.66016 8.82812 1.58984 9.10938 1.58984 9.28516C1.58984 9.42578 1.66016 9.70703 1.73047 9.88281C3.62891 14.207 7.42578 17.125 11.7148 17.125C13.332 17.125 14.8438 16.7383 16.25 16L13.6133 13.9609C13.0156 14.207 12.418 14.3477 11.75 14.3477ZM22.6484 16.7734L19.0273 13.8906C20.1172 12.7656 21.0664 11.4297 21.7344 9.88281C21.8047 9.70703 21.8398 9.42578 21.8398 9.28516C21.8398 9.10938 21.8047 8.82812 21.7344 8.65234C19.8359 4.32812 16.0391 1.41016 11.7148 1.41016C9.53516 1.41016 7.46094 2.14844 5.73828 3.48438L1.83594 0.460938C1.69531 0.320312 1.51953 0.25 1.34375 0.25C1.0625 0.25 0.816406 0.390625 0.675781 0.601562C0.359375 0.953125 0.429688 1.48047 0.816406 1.76172L21.6289 18.0742C21.9805 18.3906 22.5078 18.3203 22.7891 17.9336C23.1055 17.582 23.0352 17.0547 22.6484 16.7734ZM16.7773 9.28516C16.7773 10.1289 16.5664 10.9727 16.1797 11.6758L14.8086 10.6211C14.9844 10.1992 15.0898 9.74219 15.0898 9.28516C15.0898 7.42188 13.5781 5.91016 11.7148 5.91016C11.6445 5.91016 11.5391 5.91016 11.4336 5.91016C11.6094 6.26172 11.7148 6.61328 11.7148 7.03516C11.7148 7.38672 11.6445 7.70312 11.5039 8.01953L8.33984 5.55859C9.25391 4.71484 10.4141 4.22266 11.7148 4.22266C14.5273 4.22266 16.7773 6.47266 16.7773 9.28516Z"
          fill="#464646"
        />
      </svg>
    </span>
  )
}
