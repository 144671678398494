import { H6, H7 } from '@/components/typography'
import Link from '@/components/link'
import Block from '@/layouts/block'
import { routes } from '@/lib/constants'

const Legal = ({ className = '' }) => {
  return (
    <Block className={`${className}`}>
      <H6>Legal</H6>
      <Link href={routes.rvdb.legal.privacyPolicy}>
        <H7>Privacy policy</H7>
      </Link>
      <Link href={routes.rvdb.legal.algemeneVoorwaarden}>
        <H7>Algemene voorwaarden</H7>
      </Link>
      <Link href={routes.rvdb.legal.disclaimer}>
        <H7>Disclaimer</H7>
      </Link>
      <H7>KvK 32089287</H7>
      <H7>BTW 8103.93.499.B.01</H7>
    </Block>
  )
}

export default Legal
