import { SyntheticEvent } from 'react'
import { useRouter } from 'next/router'

import Link from '@/components/link'
import { Li } from '@/components/typography'
import { NavItemSmall } from '@/interfaces/nav'

export const NavItemSmallRay = ({
  name,
  path = '',
  IconComponent,
  toggleNav,
  isActive,
}: NavItemSmall) => {
  const { push } = useRouter()

  const closeNav = (evt: SyntheticEvent) => {
    evt.preventDefault()
    push(path)
    toggleNav()
  }

  return (
    <div className="pb-10 flex items-center">
      {IconComponent && IconComponent}
      <Link href={path} onClick={closeNav}>
        <Li className={`pl-6 lg:pb-0 ${isActive ? '!text-ray-primary' : ''}`}>
          {name}
        </Li>
      </Link>
    </div>
  )
}
