import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import { P1, Q1 } from '@/components/typography'
import { ProfileCta } from '@/interfaces/schema'
import Block from '@/layouts/block'

const CTAProfile = ({
  title,
  description,
  buttonText,
  buttonPath,
}: Omit<ProfileCta, 'sys' | 'contentfulMetadata' | '_id'>) => {
  return (
    <Block className="text-center col-span-full order-5 ctaProfileCard">
      <h3 className="mb-8 Q1 Q1--ray">{title}</h3>
      <P1 className="mb-6">{description}</P1>
      <ButtonLinkPrimaryTextAndArrow className="mx-auto" href={buttonPath}>
        {buttonText}
      </ButtonLinkPrimaryTextAndArrow>
    </Block>
  )
}

export default CTAProfile
