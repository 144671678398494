export const errorCodeObj: { [key: string]: string } = {
  // Client SDK errors
  'auth/user-not-found':
    'Aii! Gebruiker niet gevonden. Zijn je ingevoerde gegevens juist?',
  'auth/invalid-uid':
    'Ai! Ongeldig ID, probeer het nogmaals of neem contact op.',
  'auth/missing-uid':
    'Ai! Het ID ontbreekt, probeer het nogmaals of neem contact op.',
  'auth/uid-already-exists':
    'ID bestaat al, probeer het nogmaals of neem contact op.',
  'auth/invalid-credential':
    'Ai! Deze combinatie van e-mail en wachtwoord konden we niet vinden.',
  'auth/invalid-email':
    'Deze e-mail lijkt niet te bestaan, heb je hem juist ingevoerd?',
  'auth/email-already-in-use':
    'Deze e-mail is al in gebruik, probeer in plaats daarvan in te loggen.',
  'auth/email-already-exists':
    'Oeps! Er bestaat al een gebruiker met dit e-mailadres. Van jou misschien?',
  'auth/invalid-email-verified': 'Ongeldig e-mailadres geverifieerd.',
  'auth/invalid-password': 'Ai! Dit wachtwoord is niet geldig.',
  'auth/internal-error': 'Oops! Er gaat wat fout. Probeer het later nog eens.',
  'auth/invalid-disabled-field': 'Ongeldig uitgeschakeld veld.',
  'auth/invalid-display-name': 'Ongeldige displaynaam.',
  'auth/invalid-phone-number':
    'Ai! Dit telefoonnummer is niet geldig. Is deze juist ingevoerd?',
  'auth/phone-number-already-exists':
    'Ai! Er bestaat al een gebruiker met dit telefoonnummer.',
  'auth/invalid-photo-url': 'Ongeldige foto-URL.',
  'auth/session-cookie-revoked': 'Sessiecookie ingetrokken.',
  'auth/session-cookie-expired': 'Sessiecookie verlopen.',
  'auth/too-many-requests':
    'Ai! De toegang is tijdelijk uitgeschakeld vanwege te veel mislukte inlogpogingen.',
  'auth/wrong-password':
    'Ai! Dit wachtwoord wordt niet herkend. Probeer het nog eens.',
  'auth/weak-password':
    'Let op: Het wachtwoord moet minimaal 6 tekens lang zijn.',
  'auth/requires-recent-login': 'Vereist een recente login',
  'auth/invalid-verification-code':
    'De link in de email doet het niet. Zou je opnieuw willen inloggen?',
  'auth/invalid-action-code': 'Ai! Deze actiecode is niet geldig...',
  // Admin SDK errors
  'auth/id-token-expired':
    'Oops! Je bent te lang ingelogd geweest. Zou je opnieuw in willen loggen?',
  'auth/unauthorized-continue-uri':
    'Interne fout: ongeautoriseerde continue URI.',
  'auth/email-not-found':
    'Ai! We konden geen gebruiker met dit emailadres vinden. Probeer het nog eens...',
}

export const getErrorMsg = (errorCode: string): string => {
  return errorCodeObj[errorCode] || errorCode
}
