import { Form, Formik, FormikHelpers, FormikValues } from 'formik'

import GenericFormProps from '@/interfaces/form'

export default function GenericFormElement<T extends FormikValues>({
  initialValues,
  submitForm,
  children,
  formId,
  validationSchema,
  wrapperClassName = '',
  elementClassName = '',
}: GenericFormProps<T>) {
  return (
    <div className={`form__wrapper ${wrapperClassName}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values: T, actions: FormikHelpers<T>) => {
          await submitForm(values, actions)
        }}
      >
        {(props) => {
          return (
            <Form
              id={formId}
              onChange={props.handleChange}
              onSubmit={props.handleSubmit}
              onReset={props.handleReset}
              className={`form__element ${elementClassName}`}
            >
              {typeof children === 'function' ? children(props) : children}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
