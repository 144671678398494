import IconProps from '@/interfaces/icon'

export const SearchIcon = ({
  width = '26',
  height = '22',
  className = '',
  onClick,
}: IconProps) => {
  return (
    <span className={`icon ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.625 22.625L17.3438 16.3438C18.7031 14.6562 19.5 12.5469 19.5 10.25C19.5 4.90625 15.0938 0.5 9.75 0.5C4.35938 0.5 0 4.90625 0 10.25C0 15.6406 4.35938 20 9.75 20C12 20 14.1094 19.25 15.7969 17.8906L22.0781 24.1719C22.2656 24.4062 22.5469 24.5 22.875 24.5C23.1562 24.5 23.4375 24.4062 23.625 24.1719C24.0938 23.75 24.0938 23.0469 23.625 22.625ZM2.25 10.25C2.25 6.125 5.57812 2.75 9.75 2.75C13.875 2.75 17.25 6.125 17.25 10.25C17.25 14.4219 13.875 17.75 9.75 17.75C5.57812 17.75 2.25 14.4219 2.25 10.25Z" />
      </svg>
    </span>
  )
}
