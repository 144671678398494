import { useId, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { RvdbNavSecondaryProps } from '@/interfaces/nav'
import NavItemLarge from './item-large'

const RvdbNavSecondaryLarge = ({
  secondaryItems,
  openNavItem,
  toggleNavItem,
  toggleNavAndNavItems,
  activeMenuItem,
}: RvdbNavSecondaryProps) => {
  const id = useId()
  const ref = useRef(null)
  useOnClickOutside(ref, toggleNavAndNavItems)

  return (
    <div
      className="rvdbNavLarge__secondary w-full flex flex-col md:flex-row items-end justify-end gap-6"
      ref={ref}
    >
      {secondaryItems?.map(
        (
          {
            name,
            path,
            navigationSubItemsCollection,
            centerNavigationCardsCollection,
            rightBlockContent,
            rightBlockButtonText,
            rightBlockButtonPath,
            displayRayButton,
            displaySocials,
          },
          index
        ) => {
          return (
            <NavItemLarge
              key={`${id}-${index}`}
              id={name}
              path={path}
              name={name}
              subItems={navigationSubItemsCollection?.items}
              navCards={centerNavigationCardsCollection?.items}
              rightBlockContent={rightBlockContent}
              rightBlockButtonText={rightBlockButtonText}
              rightBlockButtonPath={rightBlockButtonPath}
              displayRayButton={displayRayButton}
              displaySocials={displaySocials}
              openNavItem={openNavItem}
              toggleNavItem={toggleNavItem}
              toggleNavAndNavItems={toggleNavAndNavItems}
              activeMenuItem={activeMenuItem}
            />
          )
        }
      )}
    </div>
  )
}

export default RvdbNavSecondaryLarge
