import { default as NextLink, LinkProps } from 'next/link'

interface Props extends LinkProps {
  className?: string
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  external?: boolean
  isDisabled?: boolean
  as ?: string
}

const Link = ({
  className = '',
  href,
  locale,
  children,
  onClick,
  external = false,
  isDisabled = false,
  as,
  ...props
}: Props) => {
  return (
    <NextLink href={href} locale={locale} {...props} passHref as={as}>
      <a
        className={`link${isDisabled ? '--disabled' : ''} ${className}`}
        target={external ? '_blank' : ''}
        onClick={onClick}
      >
        {children}
      </a>
    </NextLink>
  )
}

export default Link
