export const ClockIcon = ({ className = '', width = '18', height = '18' }) => {
  return (
    <span className={`arrow__right-small ${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0.53125C4.18359 0.53125 0.28125 4.43359 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.8164 17.9688 17.7188 14.0664 17.7188 9.25C17.7188 4.43359 13.8164 0.53125 9 0.53125ZM9 16.2812C5.09766 16.2812 1.96875 13.1523 1.96875 9.25C1.96875 5.38281 5.09766 2.21875 9 2.21875C12.8672 2.21875 16.0312 5.38281 16.0312 9.25C16.0312 13.1523 12.8672 16.2812 9 16.2812ZM11.1445 12.625C11.3555 12.7656 11.6016 12.7305 11.7422 12.5195L12.4102 11.6406C12.5508 11.4297 12.5156 11.1836 12.3047 11.043L9.98438 9.32031V4.32812C9.98438 4.11719 9.77344 3.90625 9.5625 3.90625H8.4375C8.19141 3.90625 8.01562 4.11719 8.01562 4.32812V10.1289C8.01562 10.2344 8.05078 10.375 8.15625 10.4453L11.1445 12.625Z"
          fill="#464646"
        />
      </svg>
    </span>
  )
}
