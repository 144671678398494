import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import { Divider, DividerColors } from '@/components/divider'
import Image from '@/components/image'
import { H3, P1 } from '@/components/typography'
import { useSmallScreen } from '@/hooks/useMediaQuery'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'

export interface StandardCardProps {
  className?: string
  icon?: Asset
  title?: string
  description?: string
  buttonText?: string
  buttonPath?: string
}

const StandardCard = ({
  className = '',
  icon,
  title = '',
  description = '',
  buttonText = '',
  buttonPath = '',
}: StandardCardProps) => {
  const isSmallScreen = useSmallScreen()
  return (
    <Block className={`${className} standardCard`}>
      <div className="flex items-center mb-[20px]">
        {icon && (
          <Image
            src={icon?.url ?? ''}
            height={isSmallScreen ? 30 : 40}
            width={isSmallScreen ? 18 : 30}
            objectFit="contain"
            alt=""
            className="mb-[4px] mr-[20px] w-[18px] md:w-[30px]"
          />
        )}
        <H3>{title}</H3>
      </div>
      <P1>{description}</P1>
      <Divider color={DividerColors.BLACK} className="!mt-auto" />
      <ButtonLinkPrimaryTextAndArrow className="ml-auto" href={buttonPath}>
        {buttonText}
      </ButtonLinkPrimaryTextAndArrow>
    </Block>
  )
}

export default StandardCard
