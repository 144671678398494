import { useId } from 'react'

import Link from '@/components/link'
import { P1 } from '@/components/typography'
import { RvdbNavPrimaryLargeProps } from '@/interfaces/nav'
import SearchBar from '@/components/global-search'

export default function RvdbNavPrimaryLarge({
  className = '',
  primaryItems,
}: RvdbNavPrimaryLargeProps) {
  const id = useId()

  return (
    <div
      className={`${className} rvdbNavLarge__primary w-full flex items-center justify-end gap-6 h-[50px]`}
    >
      <SearchBar toggleNavAndNavItems={null} />
      {primaryItems?.map((item, index) => {
        return (
          <Link
            className="shrink-0"
            key={`${id}-${index}`}
            href={item.path ?? ''}
          >
            <P1 className="primaryItem">{item.name}</P1>
          </Link>
        )
      })}
    </div>
  )
}
